import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { useEffect, useRef, useState } from 'react';
import { badge } from './EventCalendar.Badge';
import { CalendarHeader } from './EventCalandar.Header';
import moment from 'moment';
import updateLocale from 'dayjs/plugin/updateLocale';
import { dateIndex } from 'utilities/utility.calendar';

dayjs.extend(updateLocale);

function getRandomNumber(min, max) {
  return Math.round(Math.random() * (max - min) + min);
}

                                             /**
 * Mimic fetch with abort controller https:  //developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
 * ⚠️ No IE11 support
 */

function fakeFetch(date, { signal }) {
  return new Promise ((resolve, reject) => {
    const timeout = setTimeout(() => {
      const daysInMonth = date.daysInMonth();
        // const daysToHighlight = [1, 2, 3].map(() => getRandomNumber(1, daysInMonth));
      const daysToHighlight = [1, 2, 3].map((date) => {
        const d = `${Math.floor(Math.random() * 31) + 1}-${dayjs().month() + 1}-2024`
        
          // console.log(dayjs(d, "D-M-YYYY").format('YYYY-MM-DD'), dayjs(dayjs(d, "D-M-YYYY").format('YYYY-MM-DD')))
        return dayjs(dayjs(d, "D-M-YYYY").format('YYYY-MM-DD'))
      })
      resolve({ daysToHighlight });
    }, 500);

    signal.onabort = () => {
      clearTimeout(timeout);
      reject(new DOMException('aborted', 'AbortError'));
    };
  });
}


export const EventCalendar = () => {
  const [initialEventDate, setInitialEventDate] = useState(dayjs(new Date()))
  const requestAbortController                  = useRef(null);
  const [isLoading, setIsLoading]               = useState(false);
  const [highlightedDays, setHighlightedDays]   = useState([1, 2, 15]);

  const fetchHighlightedDays = (date) => {
    const controller = new AbortController();
    fakeFetch(date, {
      signal: controller.signal,
    })
      .then(({ daysToHighlight }) => {
        setHighlightedDays(daysToHighlight);
        setInitialEventDate(daysToHighlight[0])
        setIsLoading(false);
      })
      .catch((error) => {
            // ignore the error if it's caused by `controller.abort`
        if (error.name !== 'AbortError') {
          throw error;
        }
      });

    requestAbortController.current = controller;
  };


    // Replace "en" with the name of the locale you want to update.
  dayjs.updateLocale('en', {
      // Sunday = 0, Monday = 1.
    weekStart: 1,
  });


  useEffect(() => {
    fetchHighlightedDays(dayjs(new Date()));
        // abort request on unmount
    return () => requestAbortController.current?.abort();
  }, []);


  return (
    <LocalizationProvider dateAdapter = {AdapterDayjs}>
      <DateCalendar
        value    = {initialEventDate}
        onChange = {(newValue) => {
          //   const dIindex = dateIndex(initialEventDate, newValue)
          // if(dIindex >= 0) {
          //   if(dIindex) {
          //     let newDates = [...initialEventDate]
          //     newDates.splice(dIindex, 1)
          //     setInitialEventDate(newDates)
          //   }
          // }else 
          if(dateIndex(highlightedDays, newValue) >= 0) {
            // setInitialEventDate((value)=> [...value, newValue])
            setInitialEventDate(newValue)
          }
      }}
        loading               = {isLoading}
        renderLoading         = {() => <DayCalendarSkeleton />}
        disableHighlightToday = {true}
        slots                 = {{
          day           : badge,
          calendarHeader: CalendarHeader
        }}
        slotProps={{
          day: {
            highlightedDays,
            initialEventDate
          },
        }}
        dayOfWeekFormatter = {(date) => getDayNames('en', date)}
        sx                 = {{ background: '#fff', borderRadius: '6px', margin: 'unset' }}
      />
    </LocalizationProvider>
  );
}

const getDayNames = (locale = 'en', date) => {
      // Set the desired locale dynamically
    moment.locale(locale);
      // Generate a list of day names based on the dynamic locale
    return moment(date['$d']).format('dddd').substring(0, 3)
  };
  