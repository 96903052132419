import styled from 'styled-components';
import { BREAK_ } from 'constants'


export const Container = styled.div`
 
  height: 416px;
  // min-width: 756px;
  border-radius:  6px;
  position: relative; /* Make the container relative for the map */
  // margin: 40px 45px 0px 40px;
  box-sizing: border-box;
 ${ BREAK_.md_dn } { 
   
 
     border-radius: 0px 0px 0px 0px;
     margin : 0 auto;
  
  }
  /* Hide the Mapbox logo using CSS */
  .mapboxgl-ctrl-logo {
    display: none !important; /* Ensure that the Mapbox logo is completely hidden */
  }
     
`;

export const ButtonsContainer = styled.div`


  position: relative;
  bottom: 65px;
  left: 20px;
  display: flex;
  gap: 10px; /* Add space between buttons */
  z-index: 150; /* Keep buttons above the map */
    ${ BREAK_.md_dn } { 
  left: 20px;
     
  }
`;

export const ResetButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: #FFFFFFbf;
  color: white;
  border: 1px solid #D1D1D1;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
   padding: 2px 4.5px 2px 4.5px; 

  &:hover {
    transform: scale(1.1);
    background-color: #FFF;
  }
`;
export const fullscreenstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  maxWidth: "100%",
  maxHeight: "100%",
  boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  outline: "none",
  padding: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};
export const FullScreenButton = styled.button`
position: absolute;
  width: 40px;
  height: 40px;
  top: -90px;
  right:40px;
  background-color: #FFFFFFbf;
  color: white;
  border: 1px solid #D1D1D1;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
   padding: 2px 4.5px 2px 4.5px; 

  &:hover {
    transform: scale(1.1);
    background-color: #FFF;
  }
`;

export const ToggleButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: #FFFFFFbf;
  color: white;
  //  border: 2px solid ${(props) => (props.isSatellite ? "black" : "white")};
   border: 1px solid #D1D1D1;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; 

  &:hover {
    transform: scale(1.1);
    // background-color: #222;
  }


img {
    width: 100%; /* Make image take full width */
    height: 100%; /* Make image take full height */
    object-fit: contain; /* Maintain aspect ratio */
     border-radius: 6px;
  }

`;

export const PopupContainer = styled.div`
  position: absolute;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 110;
  width: 200px;
  transform: translate(-50%, -100%); /* Center horizontally and shift up vertically */
  pointer-events: none; /* Disable interaction with the popup */
`;


// Pill-shaped container for zoom buttons
export const ZoomControlsContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 40px;
  width: 42px;
  height: 80px;
  border : 1px solid #D1D1D1;
 background-color: #FFFFFFBF;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 150; /* Keep above the map */
   ${ BREAK_.md_dn } { 
  right: 35px;
  
     
  }

  @media (max-width: 1660px) {
    right: 40px; /* Keep a consistent right position for screens less than 1660px */
  }

`;

// Individual button styles
export const ZoomInButton = styled.button`
  width: 40px;
  height: 40px;
  background-color:  #FFFFFFBF;
border :none;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
padding : 10px;
  &:hover {
    // transform: scale(1.1);
    background-color: #f7f7f5;
  }

 
`;
export const ZoomOutButton = styled.button`
  width: 40px;
  height: 40px;
  background-color:  #FFFFFFBF;
border : none;
  border-radius: 0px 0px 6px 6px;
  padding : 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    // transform: scale(1.1);
    background-color: #f7f7f5;
  }

 
`;

