import { atom, useAtom } from "jotai";

const isOpenOpenHouseSignUpDrawer = atom(false);
const OpenHouseSignUpDrawerUnit = atom(null);

const useOpenHouseSignUpDrawer = () => {
  const [isOpen, setIsOpen] = useAtom(isOpenOpenHouseSignUpDrawer);
  const [unit, setUnit] = useAtom(OpenHouseSignUpDrawerUnit);

  const toggle = () => {
    if (isOpen) {
      // set null if closed.
      setUnit(null);
    }

    setIsOpen(!isOpen);
  };

  const open = (unit) => {
    setUnit(unit);
    setIsOpen(true);
  };

  return { toggle, open, isOpen, unit };
};

export { useOpenHouseSignUpDrawer };
