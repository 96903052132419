import styled from "styled-components/macro";
import { BREAK_ } from "constants";
import { MENU } from "constants";

export const Aside = styled.aside`
  background: ${({ clr, filter }) => filter ? `${clr?.primary}e6` : "transparent"};

  
  ${BREAK_.md_dn} {
    position: fixed;
    top: ${({ filter }) => (filter ? "132px" : "70px")};
    height: 100%;
    width: 100%;
    z-index: 55;
  }
  ${BREAK_.md_up} {
    z-index:10;
    height: ${({ filter, matches }) => (filter ? matches ? "auto" : "auto" : "100%")};
    width: ${({ w }) => w}px;
    min-width:${({mw})=>mw}px;
    position: fixed;
    ${({ filter,isIsometry }) => (filter  ? { bottom: isIsometry  ? "102px": "102px" } : { top: "72px" })};
    left: ${({ left, nav }) => (left ? 0 + (nav ? 300 : 48) + "px" : "unset")};
    right: ${({ right }) => (right ? 0 : "unset")};
    background-color :${({ filter }) => (filter ? "none" : "#FFF" )};
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`;
