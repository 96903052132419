import { Badge, styled } from "@mui/material";
import { PickersDay } from "@mui/x-date-pickers";
import dayjs from 'dayjs';

export const badge = (props) => {
    const { highlightedDays = [], day, initialEventDate, outsideCurrentMonth, ...other } = props;
    // const eventSelected = !props.outsideCurrentMonth && initialEventDate.findIndex((date) => dayjs(date).isSame(props.day)) >= 0
  
    const isSelected = !props.outsideCurrentMonth && highlightedDays.findIndex((date) => dayjs(date).isSame(props.day)) >= 0;

    return (
      <MuiBadgeCust
        key={props.day.toString()}
        overlap="circular"
        badgeContent={isSelected ? <Dot /> : undefined}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
      <PickersDay sx={{ fontSize: "16px", fontFamily: "Cerebri sans", fontWeight: "500" }} {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      </MuiBadgeCust>
    );
  }

  const Dot = styled('div')(
    ({ }) => ({
      width: "4px",
      height: "4px",
      borderRadius: "50%",
      background: '#B07237',
    }),
  );

  const MuiBadgeCust = styled(Badge)(() => ({
    "& .MuiBadge-badge": {
      right: "49%",
      bottom: "5px",
      width: "4px",
      height: "4px",
    }
 }));
 