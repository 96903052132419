import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  active_project,
  vis_calculate_benefit,
  vis_deposite,
  vis_movein_price,
  vis_prepaidrent,
} from "state/store.global";
import { getFocus, useAtom, useGet } from "state/jotai";
import { useHousingBenefit } from "components/HousingBenefit/HousingBenefit.hook";
import { _project } from "state/store.projects";
import { Icon } from "assets/Icons_";

function DropdownProperties({ groups, indent, isRent }) {
  const setBenefit = useAtom(vis_calculate_benefit)[1];
  const output = useHousingBenefit();
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  // State to track the visibility of each group
  const [visibleGroups, setVisibleGroups] = useState({});
  const setMovePrice = useAtom(vis_movein_price)[1];
  const setDepositePrice = useAtom(vis_deposite)[1];
  const setPrePaidRent = useAtom(vis_prepaidrent)[1];

  const toggleGroupVisibility = (groupIndex) => {
    setVisibleGroups((prevVisibleGroups) => ({
      ...prevVisibleGroups,
      [groupIndex]: !prevVisibleGroups[groupIndex],
    }));
  };

  return (
    <Box
      sx={{
        px: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        pt: "21px",
      }}
    >
      {groups &&
        groups.map((group, groupIndex) => {
        
          return (
            <Box
              key={groupIndex}
              sx={{
                borderRadius: "6px",
                backgroundColor: "#F3F3F3",
                border: visibleGroups[groupIndex] && "1px solid #D1D1D1",
                // mb: 5,
                // "&:last-child": {
                //   mb: "0",
                // },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom:
                    visibleGroups[groupIndex] && "1px solid #D1D1D1", // Border in the outer box
                }}
              >
                <Box
                  sx={{
                    // fontWeight: 700,
                    padding: "14px 16px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "space-between",
                    transition: "transform 0.3s ease-in-out ,opacity 0.3s ease-in-out , color 0.3s ease-in-out", // Smooth effect
                    overflow: "hidden", // Prevent overflow when scaling
                    transformOrigin: "center", // Scale from the center of the element
                    "&:hover": {
                      transform: "scale(1.04)", // Hover effect on the dropdown
                    },
                  }}
                  onClick={() => toggleGroupVisibility(groupIndex)}
                >
                  <div className="h4">{group.title}</div>
                  <Icon
                    icon="chevronDown"
                    color="#000"
                    isFaIcon={true}
                    size="lg"
                    wrap={{
                      transform: `rotate(${
                        visibleGroups[groupIndex] ? "180" : "0"
                      }deg)`,
                    }}
                  />
                </Box>
              </Box>
              <div
                style={{
                  display: visibleGroups[groupIndex] ? "block" : "none",
                  padding: "8px 16px",
                }}
              >
                {group.data &&
                  group.data.map((data, dataIndex) => {
                  
                    const label = data.Label;
                    const value =
                      data.Value === true
                        ? buttonsLabel?.filter(
                            (btn) => btn?.UILabelProperty === "Ja"
                          )[0]?.Label
                        : data.Value === false
                        ? buttonsLabel?.filter(
                            (btn) => btn?.UILabelProperty === "Nej"
                          )[0]?.Label
                        : data.Value;
                    const postfix = data.Postfix ?? "";
                    if (data.PropertyID === 113 && !isRent) return <></>;
                    // if value is one of them then we don't have to show it in the frontend
                    if (
                      value === "" ||
                      value === null ||
                      value === undefined ||
                      value === "999.999" ||
                      value === "999999" ||
                      value === 0 ||
                      value === "0"
                    )
                      return null;
                    return (
                      <>
                        {Boolean(value) && (
                          <Box
                            key={dataIndex}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              px: indent ? 3 : 0,
                              py: 1.5,
                              borderBottom: "1px solid #D1D1D1",
                              "&:last-child": {
                                borderBottom: "none",
                              },
                            }}
                          >
                            <Box sx={{ display: "flex", gap: "2px" }}>
                              <div className="paragraph2-light">{label}</div>
                              {data.PropertyID == 102 ? (
                                <Box
                                  onClick={() => setMovePrice(true)}
                                  sx={{
                                    color: "unset",
                                    cursor: "pointer",
                                    position: "relative",
                                    top: "3px",
                                    left: "1px",
                                  }}
                                >
                                  <Icon icon="info" color="#000" size="11" />
                                </Box>
                              ) : (
                                ""
                              )}

                              {data.PropertyID == 106 ? (
                                <Box
                                  onClick={() => setDepositePrice(true)}
                                  sx={{
                                    color: "unset",
                                    cursor: "pointer",
                                    position: "relative",
                                    top: "3px",
                                    left: "2px",
                                  }}
                                >
                                  <Icon icon="info" color="#000" size="11" />
                                </Box>
                              ) : (
                                ""
                              )}
                              {data.PropertyID == 108 ? (
                                <Box
                                  onClick={() => setPrePaidRent(true)}
                                  sx={{
                                    color: "unset",
                                    cursor: "pointer",
                                    position: "relative",
                                    top: "-1px",
                                    left: "2px",
                                  }}
                                >
                                  <Icon icon="info" color="#000" size="11" />
                                </Box>
                              ) : (
                                ""
                              )}
                            </Box>
                            {data.PropertyID !== 113 ? (
                              <div className="paragraph2-light">
                                {value}
                                {postfix}
                              </div>
                            ) : (
                              <>
                                {output ? (
                                  <div
                                    onClick={() => setBenefit(true)}
                                    style={{
                                      color: "unset",
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {output}
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => setBenefit(true)}
                                    style={{
                                      color: "unset",
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {
                                      buttonsLabel?.filter(
                                        (btn) =>
                                          btn.UILabelProperty === "Beregn"
                                      )[0]?.Label
                                    }
                                  </div>
                                )}
                              </>
                            )}
                          </Box>
                        )}
                      </>
                    );
                  })}
              </div>
            </Box>
          );
        })}
    </Box>
  );
}

DropdownProperties.propTypes = {
  groups: PropTypes.array.isRequired,
  indent: PropTypes.bool,
};

export default DropdownProperties;
