import styled from 'styled-components/macro'
import { BREAK } from 'constants'
import { BREAK_ } from 'constants'

export const Img = styled.img`width: 100%; height: 270px;display: block;`

export const Card = styled.div`
  display: flex;
  width: 100%;
  font-size: 0.8125rem;
  flex-direction: column;
  position:relative;
`

export const Line = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  :nth-child(1) { font-weight: 600; };
  border:4px solid orange;
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
`

export const Favor = styled.div`
  font-weight: bold;
  line-height: 0.6875rem;
  border:4px solid orange;
`

export const CTA = styled.div`
  display:flex;
  line-height:1;
  gap:8px;
  align-items:center;
  /* border:1px solid black; */
`

export const Low = styled.div`
  display: flex;
  gap: 30px;
  font-size:1rem;
  justify-content: flex-start;
  padding: ${({atUnit}) => atUnit ? "0px 24px" : "8px 24px"};
  height: ${({atUnit}) => atUnit ? "44px" : "49px"};
  ${ BREAK.md_dn('justify-content: space-between')};
  ${ BREAK_.md_dn } { 
    padding:  ${({atUnit}) => atUnit ? "0px 13px" : "8px 43px"};
   }
`

export const Separator = styled.div`
  width: 100%;
  min-height: 0.5px;
  margin-left: auto;
  display: block;
  ${ BREAK.md_dn('width: 100%')};
  ${ BREAK.md_dn('margin: auto')};
  ${ BREAK.md_dn('justify-content: space-between')};
  background: ${({ color }) => `${color}22`};
`

export const Status = styled.div`
  color: ${({ color }) => color};
  line-height: 0.6875rem;
  position: absolute;
  top: 15px;
  right: 24px;
  font-size: 1rem;
  ${ BREAK_.md_dn }{
   top: 21px;
   right: 16px;
  };
`