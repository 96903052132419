import { List, Main }     from 'components/List/Card/Card.style'
import { Content }        from 'components/List/Card/Card.Content'
import { get, floorplan, getThumb, getImages } from 'components/List/List.Utilities'
import { FLOORPLAN }      from 'api/api'
import { active_unit, vis_desktop_nav }    from 'state/store.global'
import { useAtom }        from 'state/jotai'
import { Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useActiveUnit } from 'components/Unit'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { ImgWrapper } from 'components/ImgContainer/ImgWrapper'

export const Card = ({ units, extra, pick, unitErrMsg, click, mode, snackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage, handleSnackbarOpen, handleSnackbarClose }) => {
  const active = useAtom(active_unit)[0]
  const [show, setShow] = useAtom(vis_desktop_nav);
  const unit = useActiveUnit();
  const thumbnails =  unit.getThumb() ;

  const [maxHeight, setMaxHeight] = useState(0);
  const unitInfoRefs = useRef([]);

  useEffect(() => {
    // Function to calculate and set max height
    let max = 0;
    if(unitInfoRefs.current) {
      unitInfoRefs.current.forEach((ref) => {
        if (ref && ref.clientHeight > max) {
          max = ref.clientHeight;
        }
      });
    }
    setMaxHeight(max);
  }, [unitInfoRefs]);

  return (
    <>
    {
      !units.length ? <Box sx={{textAlign: 'center'}}>{unitErrMsg}</Box>
      :
     <List>
     {
       extra?.map((i, j) => {
         const onClick   = () => click(i.UnitID)
         const thumbnail = floorplan(i, FLOORPLAN)
         const card      = { key:i?.UnitID, style:pick, onClick }
         const content   = { unit:i, thumbnail, color:get(i,'UnitColor'),snackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage, handleSnackbarOpen, handleSnackbarClose}
         const isActive = active.UnitID === i.UnitID
         const color = isActive ? {background:`${get(i,'UnitColor')}66`} : {}

         const sliderImage = getImages(i, true) ?? []
         const sliderThumbImage = getThumb(i, true) ?? []

         const props = { card, color: get(i,'UnitColor'), style: color, sliderImage, sliderThumbImage, content, i }

         return (
          <MainWrapper {...props} />
         )
     })}
     {
       units?.map((i, j) => {
        const onClick   = () => {click(i.UnitID); setShow(false);}
        const thumbnail = floorplan(i, FLOORPLAN)
         const card      = { key: i?.UnitID, onClick }
         const content   = { unit:i, thumbnail, color:get(i,'UnitColor'), mode, snackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage, handleSnackbarOpen, handleSnackbarClose}

         const isActive = active.UnitID === i.UnitID
         const color = isActive ? {background:`${get(i,'UnitColor')}66`} : {}
         
         const sliderImage = getImages(i, true) ?? []
         const sliderThumbImage = getThumb(i, true) ?? []

         const props = { card, color: get(i,'UnitColor'), style: color, sliderImage, sliderThumbImage, content, i }
         
         return (
          <MainWrapper {...props} />
         )
     })}
   </List>
    }
      {/* <div style={{ height: 10, background: '#fff' }} /> */}
    </>
  )
}

4
const MainWrapper = ({ card, color, style, sliderImage, sliderThumbImage, i, content }) => {
  
  const [ref, isIntersecting] = useIntersectionObserver({ threshold: 0.1 });

  return <div ref={ref}>
            <Main {...card} color={color} style={style}>
                <Content {...content} />
              </Main>
          </div>
}
