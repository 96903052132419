import { colors } from "@mui/material";
import { Icon } from "assets/Icons_";
import React from "react";
import styled from "styled-components";

const DirectionPopupContainer = styled.div`

  padding: 15px;
color:black;

`;

const PopupHeader = styled.div`
 margin-bottom: 15px;
  
`;

const PopupPlaceName = styled.div`
//    margin-bottom: 10px;
  
`;

const TransportModesContainer = styled.div`

    background: white;  /* Set background to white */
  height: 50px;      /* Set your desired height */
  display: flex; 
    justify-content: space-between;
   gap: 5px;
  align-items: center; /* Optional: center content vertically */
 border-radius: 0 0 20px 20px;
`;

const TransportMode = styled.div`
  display: flex;
  align-items: center;
//   cursor: pointer;
  transition: 0.3s;
  padding: 0 15px;
  
`;



const TransportDuration = styled.span`
  margin-left: 10px;
  
  color : ${({clr}) => clr?.buttons };
`;

const CustomPopup = ({ poi, walkingDuration, cyclingDuration, drivingDuration ,clr}) => (
    <>
  <DirectionPopupContainer>
    <PopupHeader className="h3">{poi.text}</PopupHeader>
    <PopupPlaceName className="paragraph2-regular">{poi.place_name}</PopupPlaceName>
    </DirectionPopupContainer>
    <TransportModesContainer>
      <TransportMode >
      <Icon  color={clr?.buttons} size={"xl"} isFaIcon={true}   icon="walking"/>
        <TransportDuration className="paragraph1-regular" clr={clr}> {walkingDuration} min.</TransportDuration>
      </TransportMode>
      <TransportMode>
      <Icon  color={clr?.buttons} size={"xl"} isFaIcon={true} icon="cycling"/>
        <TransportDuration className="paragraph1-regular" clr={clr}> {cyclingDuration} min.</TransportDuration>
      </TransportMode>
      <TransportMode>
      <Icon  color={clr?.buttons} size={"xl"}  isFaIcon={true} icon="driving"/>
        <TransportDuration className="paragraph1-regular" clr={clr}> {drivingDuration} min.</TransportDuration>
      </TransportMode>
    </TransportModesContainer>
    </>
  
);

export default CustomPopup;
