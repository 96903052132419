import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { Main, Section } from "./SelectFunction.style";
import { Box } from "@mui/material";
import { Icon } from "assets/Icons_";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { ShiftProperty } from "components/ShiftProperty/ShiftProperty";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { _bootstrap } from "state/store.bootstrap";
import { useBreak } from "hooks/useBreak";
import { getIsoData } from "state/jotai.isometryDataHooks";
import { Status } from "components/StatusPopup/StatusPopup";
import { _project } from "state/store.projects";
import { DesktopColorLegend } from "components/ColorLegend/DesktopColorLegend";
import { ColorLegend } from "components/ColorLegend/ColorLegend";
import { FooterButton } from "components/DualMenu/DualMenu.style";
import { ETStyledButton } from "components/Button/EusButton";

export const SelectFunction = ({
  showSelectFunction,
  setShowSelectFunction,
}) => {
  const { pin, pid } = useGet(active_project);
  const [showProperty, setShowProperty] = useState(false);
  const here = useLocation().pathname;
  const atModel = here.includes("isometry");
  const projectType = getFocus(_bootstrap, "type");
  const isMobile = useBreak("md_dn");
  const [state, setState] = useState(false);
  const percent = getIsoData("percent");

  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  // console.log(buttonsLabel,"ffff")
  const showButton = [
    "list",
    "compare",
    "favorites",
    "gallery",
    "downloads",
  ].some((i) => here.includes(i));

  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5, ease: "linear" },
  };

  return (
    <>
      <AnimatePresence>
        {!state && showSelectFunction && (
          <>
            <Main as={motion.div} {...animation} clr={clr}>
              <Box
                sx={{
                  color: "#fff",
                  borderBottom: `1px solid ${clr.primary_text}`,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                    padding: "16px 16px 16px 48px ",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      color: clr?.primary_text,
                    }}
                  >
                    <span className="h5">
                      {" "}
                      {
                        buttonsLabel?.filter(
                          (btn) => btn.UILabelProperty === "VælgFunktion"
                        )[0]?.Label
                      }
                    </span>
                  </Box>
                </Box>
                <Box
                  sx={{ padding: "16px" }}
                  onClick={() => {
                    setTimeout(() => {
                      setShowSelectFunction(false);
                    }, 150);
                  }}
                >
                  <span className="icon-hover-effect">
                    <Icon
                      icon={"close"}
                      size="xl"
                      isFaIcon={true}
                      color={clr?.primary_text}
                    />
                  </span>
                </Box>
              </Box>
              <Box sx={{ padding: "16px 16px 44px" }}>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "16px" }}
                >
                  {/* <Section
                  isMobile={isMobile}
                  pin={pin}
                  onClick={() => {
                    setShowProperty(!showProperty);
                    // toggleDrawer();
                  }}
                  clr={clr}
                  showButton={showButton}
                >
                  <Icon
                    icon="buildings"
                    size="lg"
                    color= "#000"
                    isFaIcon={true}
                  />
                  Skift ejendom
                  <ShiftProperty
                    showProperty={showProperty}
                    setShowProperty={setShowProperty}
                  />
                </Section> */}
                  {/* <Section
                    pin={pin}
                    onClick={() => {
                        setShowProperty(!showProperty);
                        // toggleDrawer();
                    }}
                    clr={clr}
                    showButton={showButton}
                    >
                    <Icon
                        icon="buildings"
                        size="lg"
                        color={pin > 0 || showButton ? "#000" : "#000"}
                        isFaIcon={true}
                    />
                     <div className="paragraph1-regular"  style={{ color: "#000000" }}>
                          Skift ejendom
                      </div>
                    <ShiftProperty
                        showProperty={showProperty}
                        setShowProperty={setShowProperty}
                    />
                  </Section> */}

                  {projectType === "poly" && pin !== 0 && atModel && (
                    <ETStyledButton isMobile={isMobile} pin={pin} clr={clr}>
                      <Status
                        pin={pin}
                        state={state}
                        setState={setState}
                        isMobile={isMobile}
                        {...{ percent }}
                      />
                    </ETStyledButton>
                  )}
                  {projectType === "mono" && (
                    <ETStyledButton
                      variant={"secondary"}
                      isMobile={isMobile}
                      pin={pin}
                      clr={clr}
                    >
                      <Status
                        pin={pin}
                        state={state}
                        setState={setState}
                        isMobile={isMobile}
                        {...{ percent }}
                      />
                    </ETStyledButton>
                  )}
                </Box>
              </Box>
            </Main>
          </>
        )}
        {isMobile && (
          // <Portal id={'StatusColorLegend'}>
          <AnimatePresence>
            {state && (
              <>
                {/* <Backdrop onClick={()=>setState(false)}/> */}
                <ColorLegend setState={setState} />
              </>
            )}
          </AnimatePresence>
        )}
      </AnimatePresence>
    </>
  );
};
