import { Grid, Box, Button, Stack } from "@mui/material";
// import { Icon } from "components/Icons";
import { useOpenHouseData } from "components/OpenHouseBanner/OpenHouseBanner.Data";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useActiveUnit } from "./hooks";
import { useLeadDrawer } from "components/Contact/useLeadDrawer";
import { useOpenHouseSignUpDrawer } from "components/Contact/useBookViewingDrawer";
import { Icon } from "assets/Icons_";

function OpenHouse() {
  const textRef = useRef(null);
  const unit = useActiveUnit();
  const unitId = parseInt(unit.getId());
  const [isAddrExpanded, setIsAddrExpanded] = useState(false);

  const { ready, MM, MMM, Title, Time, Description, Address } =
    useOpenHouseData();
  const {
    i18n: { language },
  } = useTranslation();

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const { open } = useLeadDrawer();
  const { open: openHouseSignUpOpen } = useOpenHouseSignUpDrawer();

  const handleContact = () => {
    open(unit);
  };

  const handleSignUp = () => {
    openHouseSignUpOpen(unit);
  };

  useEffect(() => {
    if (textRef.current) {
      const width = textRef.current.getBoundingClientRect().width;
      if (width + 16 > 270) {
        setIsAddrExpanded(true);
      }
    }
  }, [unitId]);

  return (
    <>
      {ready && (
      <Box
        sx={{
          px: {
            xs: isAddrExpanded ? "24px" : 2.625,
            lg: isAddrExpanded ? "24px" : 6.25,
          },
          my: { xs: 2, md: 4 },
        }}
      >
        <Stack
          direction="row"
          sx={{
            py: { xs: 1, md: 1 },
            minHeight: "104px",
            border: "1px solid",
            borderColor: "common.black",
            lineHeight: 1.3,
            borderRadius: "6px",
            width: isAddrExpanded ? "402px" : "auto",
            mx: "auto",
          }}
        >
          {/* left */}
          <Box
            sx={{
              px: { xs: 1 },
              borderRight: "1px solid",
              minWidth: "77px",
              borderRightColor: "common.black",
              textAlign: "center",
              lineHeight: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flexGrow: { md: 1 },
            }}
          >
            <Box sx={{ fontSize: 35, fontWeight: "bold", mb: "2px" }}>{MM}</Box>
            <Box sx={{ fontSize: 16 }}>{MMM}</Box>
          </Box>
          {/* right */}
          <Box
            sx={{
              px: { xs: 1 },
              flexGrow: { md: 5 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <strong className="paragraph1-bold">
              {
                buttonsLabel?.filter(
                  (btn) => btn.UILabelProperty === "OpenHouseTitle"
                )[0]?.Label
              }
              &nbsp;{Title}
            </strong>
            <div className="paragraph2-light">{Time}</div>
            <div
              ref={textRef}
              className="paragraph2-light"
              style={{ width: "fit-content" }}
              dangerouslySetInnerHTML={{ __html: Address }}
            ></div>
            {/* commented unit we get a proper openhouse link */}
            <Button
              className="paragraph1-light "
              onClick={handleContact}
              href={``}
              sx={{
                px: 0,
                py: 0.5,
                justifyContent: "flex-start",
                width: "fit-content",
                background: "none",
                boxShadow: "none",
                gap: "8px",
                alignItems:"center",
                transition:
                  "transform 0.3s ease-in-out, opacity 0.3s ease-in-out;",
                "&:hover": {
                  background: "none",
                  transform: "scale(1.1)",
                  opacity: 1,
                  boxShadow: "none",

                },

                ".MuiButton-endIcon": {
                  // transition: "none", // Prevent icon from scaling
                  marginTop: "2px", // Adjust margin for better alignment
                },
              }}
              // endIcon={<Icon icon="long-arrow" />}
            >
              {
                buttonsLabel?.filter(
                  (btn) => btn.UILabelProperty === "OpenHouseBtnLabel"
                )[0]?.Label
              }
              <span  style={{
                  transform: "rotate(180deg)", // Rotating the icon by 180 degrees
                }}>
              <Icon
                icon="back"
                isFaIcon={true}
                size={"xl"}
                color={clr?.buttons}
               
              />
              </span>
            </Button>
            {/* <Button  className="paragraph1-light" onClick={handleSignUp} href={``} sx = {{ px: 0, py: 0.5, justifyContent: "flex-start", width: "fit-content"}} endIcon = {<Icon icon="long-arrow" />} >
                tilmeld dig
              </Button> */}
          </Box>
        </Stack>
      </Box>
      )}
    </>
  );
}

export default OpenHouse;
