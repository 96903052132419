import styled            from 'styled-components/macro'
import { BREAK_ }        from 'constants'
import { Icon }          from 'assets/Icons_'
import { useScalePoint } from 'hooks/useScalePoint' 
import { useBreak }      from 'hooks/useBreak'
import { getFocus, useGet } from 'state/jotai'
import { active_project, iso_isrotating } from 'state/store.global'
import { _bootstrap } from 'state/store.bootstrap'
import { _project } from 'state/store.projects'
import { useEffect, useState } from 'react'

export const Recenter = ({z,bounds,dim,rmode}) => {
  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const isMobile    = useBreak('md_dn')
  // const scale       = useScalePoint(bounds,dim,true) + ( isMobile ? 0.1 : 0 )
  const scale = useScalePoint(bounds,dim,true, '_', isMobile, pin)  + ( isMobile ? (pin>0 ? 0 : 0.07 ) : (pin>0 ? 0 : 0.01 ))
  const isrotate    = useGet(iso_isrotating);
  const projectType = getFocus(_bootstrap, 'type')
  

  const [currentScale, setCurrentScale] = useState(z.current?.state?.scale)

  useEffect(()=> {
    setCurrentScale(z.current?.state?.scale)
  }, [dim, z.current?.state?.scale])

  const click = () => {
    z.current?.zoomToElement('center',scale,200,'easeOut')
    setCurrentScale(scale)
  }

  const props = { projectType, pin, clr, rmode, isrotate, onClick: click, scale, currentScale: currentScale }

  return (
    <Wrap {...props}>
         { !isMobile && <Text className='paragraph1-regular'>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'NulstilVisning')[0]?.Label }</Text>}
      <Icon icon = 'readjust' size = '30' color = {"#000"}/>
   
    </Wrap>
  )

}

const Wrap = styled.div`
  border-radius   : 6px;
  background-color: ${({ clr}) => `${clr?.buttons_text}bf`};
  display    : flex;
  align-items: center;
  gap        : 6px;
  font-size  : 14px;
  width      : fit-content;
  cursor     : pointer;
  ${BREAK_.md_dn} { font-size: 0.6875rem; }
  line-height: 1;
  padding: 5px 8px;
  opacity: ${({ scale, currentScale}) => scale === currentScale ? 0.5 : 1 };s
`

const Text = styled.p`
  white-space: nowrap;
`