import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Portal } from 'components/Portal/Portal';

// Custom Alert component to remove close icon and customize tick icon
const CustomAlert = React.forwardRef(function CustomAlert(props, ref) {
  return (
    <MuiAlert ref={ref} elevation={6} variant="filled" {...props} style={{ whiteSpace: 'nowrap' }} />
  );
});

const CustomSnackbar = ({ message, onClose ,open , top , left}) => {

  return (
    <Portal id="toaster">
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        onClose={onClose}
        style={{ 
          backgroundColor: '#1C6347', 
          borderRadius: '6px',
          zIndex: 9999, 
          position: 'fixed', 
          top: "66px", 
          left: "50%", 
          transform: 'translateX(-50%)' 
        }} 
      >
        <CustomAlert
          elevation={6}
          variant="filled"
          severity="success"
          sx={{ backgroundColor: '#1C6347', color: '#fff' }}
          icon={<FontAwesomeIcon icon={faCircleCheck} />}
        >
          {message}
        </CustomAlert>
      </Snackbar>
    </Portal>
  );
};

export default CustomSnackbar;
