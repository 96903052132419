import { Box, Button, Checkbox, Drawer, FormControl, FormControlLabel, Grid, IconButton, MenuItem, OutlinedInput, Radio, RadioGroup, useMediaQuery } from "@mui/material";
import { Icon } from "components/Icons";
import React, { useEffect, useState } from "react";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { TESTEMAIL } from "api/api";
import { CustomInput, CustomLabel, CustomSelect } from "./styled-components";
import { useFetch } from "hooks/fetch/useFetch";
import axios from "axios";
import { motion } from "framer-motion";
import SuccessMessage from "./SuccessMessage";
import { useOpenHouseSignUpDrawer } from "./useBookViewingDrawer";
import { useLocation } from "react-router-dom";
import { EventCalendar } from "components/Calendar/EventCalendar";

const OpenHouseSignUpDrawer = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { isOpen, toggle, unit } = useOpenHouseSignUpDrawer();

  const { i18n: { language }, } = useTranslation();

  const [personName, setPersonName]     = useState([]);
  const [nationName, setNationName]     = useState([]);
  const [submitted, setSubmitted]       = useState(false);
  const [describeName, setDescribeName] = useState([]);

    // const { submit, successMsg, errorMsg, inProgress, submitted } = useHubspot();
  const { register, handleSubmit, reset, formState: { errors, isValid }, } = useForm();
  const here                                                               = useLocation().pathname;
  const atUnit                                                             = here.includes("unit");
  const { pin, pid }                                                       = useGet(active_project)

  const testLeadData = useFetch({ url: TESTEMAIL(language) });

  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const isDesktop = useMediaQuery('(min-width:1200px)');

  const unitNo      = unit?.getValue("Projeknr") ? `${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Bolignr')[0]?.Label} ${unit.getValue("Projeknr")},` : "";
  const unitAddress = unit?.getAddress() ?? "";
  const unitId      = unit?.getId();

  const homeUrl = window.location.origin;
  const unitUrl = unit?.getUrl(`${homeUrl}/${language}`) ?? "";
  
  
  const [checked, setChecked] = React.useState(false);

  const handleCheckbox = (event) => { setChecked(event.target.checked); };



  useEffect(()=> {
      testLeadData.mutate()
  }, [language])

  useEffect(() => {
    if (submitted) {
        setPersonName([]);
        setNationName([]);
        setDescribeName([])
        reset();
        setChecked(false);
    }
  }, [submitted]);

  useEffect(() => {
      setPersonName([]);
      setNationName([]);
      setDescribeName([])
      reset();
      setChecked(false);
      setSubmitted(false)
    testLeadData.mutate()
  }, [isOpen])


  const handleFormSubmit = async (data) => {
    const DescribeIDMapping = {
      'Studerende'              : '1',
      'Student'                 : '1',
      'Young profesional'       : '2',
      'Young Professional'      : '2',
      'Expat'                   : '3',
      'Employee / Fuldtidsansat': '4',
      'B2B'                     : '5',
      'Andet'                   : '6',
      'Other'                   : '6',
      'Personlig'               : '7',
      'Personal viewing'        : '7',
      'Digital'                 : '8',
      'Personlig fremvisning'   : '7',
      'Digital presentation'    : '8',
      'Digital fremvisining'    : '8',
      'Traveling Professional'  : '9',
      'Digital viewing'         : '8',
      'Tidsbegrænset lejer'     : '11',
      'Temporary Stay'          : '11'
  };

  const utms = {
    utm_source  : "google",
    utm_medium  : "cpc",
    utm_campaign: "spring_sale",
    utm_content : "ad_variation_1"
  }

  let utmParam = {};
  for (let [key, value] of new URLSearchParams(location.search).entries()) {
    if(key === "utm_source" || key === "utm_medium" || key === "utm_campaign" || key === "utm_content") {
      utmParam[key] = value;
    }
  }
 
    const leadNationality  = testLeadData?.D?.countries?.filter((c) => c?.name_dk === data?.nationality_id || c?.name_eng === data?.nationality_id)
    const leadCountry_code = testLeadData?.D?.countries?.filter((c) => c?.country_code === `+${data?.country_id.split("+")?.[1]}`)
     
        const payload = new URLSearchParams({
          Token           : testLeadData?.D?.token,
          Hash            : testLeadData?.D?.hash,
          Campaign        : 'a01891bb96de7ceab1b5d86de0bc8ddd',
          B_ID            : unitId,
          Fornavne        : data.firstname,
          Efternavn       : data.lastname,
          Email_privat    : data.email,
          country_code    : leadCountry_code?.[0]?.country_id,
          nationality_code: leadNationality?.[0]?.country_id,
          SELECTIONS      : `${DescribeIDMapping[data?.describe_id] ?? ""}${data.viewing_id ? `, ${data.viewing_id}` : ",0"}`,
          Telefon_mobil   : data.phone,
          Comments        : data.kommentar?.length ? data.kommentar                            : "",
          ...utmParam
        });
        
      axios.post('https://show.estatetool.net/api2/post_leadtool/', payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((data) => {
        setSubmitted(Object.keys(data || {}).length ? true : false);
      }).catch((err) => {
        enqueueSnackbar(language === "da" ? 'Noget gik galt. Prøv igen' : 'Something went wrong. Try again', { variant: 'error' });
      });
      testLeadData.mutate()
      setPersonName([]);
      setNationName([]);
      setDescribeName([])
      reset();
      setChecked(false);
  };

  const onSubmit = (data) => {
    handleFormSubmit(data);
  };

    // select dropdown configs
  
  const ITEM_HEIGHT      = 48;
  const ITEM_PADDING_TOP = 8;


  const MenuProps = {
    anchorOrigin: {
      vertical  : "bottom",
      horizontal: "left"  // Don't pass grid from contact drawer as this condition won't work
    },
    transformOrigin: {
      vertical  : "top",
      horizontal: "left"
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
          // width: dropdown_width
      },
    },
  };


  // describe field options
  const describeOption = {
    'da': [
      "Studerende",
      "Young Professional",
      "Traveling Professional",
      "Expat",
      "B2B",
      "Tidsbegrænset lejer",
      "Andet"
    ],
    'en': [
      "Student",
      "Young Professional",
      "Traveling Professional",
      "Expat",
      "B2B",
      "Temporary Stay",
      "Other",
    ]
  }

    // handling the field which should have only number allowed
  const handleNumberInput = (event) => {
    return (event.key.charCodeAt() >= 48 && event.key.charCodeAt() <= 57) || event.key === 'Backspace' ? event.key : event.preventDefault();
  }



  return (
    <Drawer
      anchor     = {isMobile ? "bottom" : "right"}
      open       = {isOpen}
      onClose    = {() => toggle()}
      PaperProps = {{ sx: { scrollbarWidth: "none", "&::-webkit-scrollbar": { display: "none" }, color: "secondary.contastText", backgroundColor: "secondary.main", }, }}
    >
      <>
      {submitted ? (
          <motion.div
          initial    = {{ opacity: 0, scale: 0.5 }}
          animate    = {{ opacity: 1, scale: 1 }}
          transition = {{ duration: 0.5 }}
          style={{ height: "100%", display: "flex", alignItems: "center" }}
        >
             <SuccessMessage label = {false} labelName = {"Signupmessage"} msg = { buttonsLabel?.filter( btn => btn.UILabelProperty === 'leadSuccessMsg')[0]?.Label } />
        </motion.div>
     
      ) :
      <>  
      <Box sx = {{ width: isMobile ? "auto" : 422, position: "relative", pt: 4, pb: 4, px: 3, lineHeight: 1.3, }} >
        <IconButton
          onClick = {toggle}
          sx      = {{
            position: "absolute",
            right   : 16,
            top     : 16,
            zIndex  : 2
          }}
        >
          <Icon
            icon = "close"
            sx   = {{
              fontSize: 18,
            }}
          />
        </IconButton>

          <Box
            sx={{
              position: 'relative',
              color   : clr.sub_text_color
            }}
          >
             <Box sx={{ mb: 4, mt: 3 }} >
          <Box sx={{ fontWeight: 700, lineHeight: 1.4, }} > 
              {/* {
                buttonsLabel?.filter( btn => btn.UILabelProperty ===  "footerHeading1")[0]?.Label
              } */}
              {
                language === 'da' ? 
                "Tilmeld til åbent hus"
                :
                "Sign up for open house"
              }
            </Box>
            <br />
            <Box sx={{ fontWeight: 400, lineHeight: 1.4, fontSize: 15}} >
            {/* { buttonsLabel?.filter( btn => btn.UILabelProperty ===  'BookShowingHeading')[0]?.Label } */}
            {
                language === 'da' ? 
                "Udfyld dine oplysninger nedenfor for at tilmelde dig åbent hus. Hvis det kommende åbent hus ikke passer dig, er det muligt at vælge mellem andre fremtidige åbent hus events i kalenderen nedenfor."
                :
                "Fill out your information below to sign up for the event. If the upcoming event doesn't fit you it is possible to select between other future events."
              }
            </Box>
          </Box>
            {/* {children} */}
            {/* progress */}

            <form onSubmit = {handleSubmit(onSubmit)}>
              <Grid
                container
                columnSpacing = {3}
                rowSpacing    = {1}
                sx            = {{ mb: 3 }}
              >

              <Grid sx={{ display: "flex", flexDirection: "column", gap: "8px", mb: 2 }} item md = {12} xs = {10}>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                    <Box>
                      <Icon icon = "event-label" sx={{ width: "16px", height: "16px" }} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                      <p className="h4"> Event</p>
                      <p className="paragraph1-light">Public event - Fælledby (dummy eido name)</p>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                    <Box>
                      <Icon icon = "event-calendar" sx={{ width: "16px", height: "16px" }} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                      <p className="h4">Date and time</p>
                      <p className="paragraph1-light">Sunday 21st July 2024<br/> 11:00 - 12:00 (1 hour)</p>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
                    <Box>
                      <Icon icon = "event-pin" sx={{ width: "16px", height: "16px" }} />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                      <p className="h4">Property address</p>
                      <p className="paragraph1-light">Kaktus Towers <br/>Dybbølsbro 3, 1., door 1<br/>1577 København V<br/>7-W260<br/></p>
                    </Box>
                  </Box>
              </Grid>
                <Grid item md = {12} xs = {10}>
                <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.firstname ? true : false}
                  >
                    <CustomLabel shrink>
                    Vælg åbent hus  &nbsp;<span>*</span>
                    </CustomLabel>
                    <Box sx={{ mt: 2 }}>
                      <EventCalendar />
                    </Box>
                  </FormControl>
              
                </Grid>
                <Grid item md={12} xs={10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.firstname ? true : false}
                  >
                    <CustomLabel shrink>
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'Formularfornavn')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput
                      {...register('firstname', { required: true, validate: value => value.trim() !== "" })}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.lastname ? true : false}
                  >
                    <CustomLabel shrink>
                      {/* { buttonsLabel?.filter( btn => btn.UILabelProperty === 'Formularfornavn')[0]?.Label } */}
                      Efternavn
                       &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput
                      {...register('lastname', { required: true, validate: value => value.trim() !== "" })}
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.phone ? true : false}
                  >
                    <CustomLabel shrink>
                     { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularTelefon')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput type="tel" onKeyDown={handleNumberInput} {...register('phone', { required: true, validate: value => value.trim() !== ""  })} />
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={10}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.email ? true : false}
                  >
                    <CustomLabel shrink>
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEmail')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput {...register('email', { required: true, validate: value => value.trim() !== ""  })} />
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                type     = 'submit'
                disabled = {
                  // !checked || 
                  !isValid}
                variant  = {"contained"}
                size     = "large"
                sx       = {{
                  textAlign   : 'center',
                  borderRadius: '6px',
                  color       : clr?.buttons_text,
                  border      : 'none',
                  padding     : "11px 24px 11px 24px",
                }}          
              >
                <span className = "paragraph1-bold" style = {{fontWeight : 600 }}>
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'signUp')[0]?.Label }
                </span>
              </Button>
            </form>
          </Box>
      </Box>
      </>
      }
      </>
    </Drawer>
  );
};

export default OpenHouseSignUpDrawer;


{/* <SuccessMessage label={false} msg={language === "da" ? <p>Vi har registreret dit ønske om at bestille en fremvisning.<br/> Du vil blive kontaktet af vores mægler for at aftale nærmere.</p> : <p>We have registered your wish to order a screening. <br />You will be contacted by our broker to make further arrangements</p>} width={422}/> */}