import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import mapboxgl from "mapbox-gl";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
import supermarketicon from "../../assets/poi_icons/shopping-basket.png";
import cafe from "../../assets/poi_icons/coffee-cup.png";
import mainMarkerIcon from "../../assets/poi_icons/svgviewer-png-output.png";
import busStation from "../../assets/poi_icons/bus.png";
import metroStation from "../../assets/poi_icons/metro-station.png";
import trainStation from "../../assets/poi_icons/DSB.png";
import satelliteIcon from "../../assets/poi_icons/satelitte.webp";
import mapIcon from "../../assets/poi_icons/map.webp";
import { debounce } from "lodash"; // Import lodash debounce

import {
  ButtonsContainer,
  Container,
  FullScreenButton,
  fullscreenstyle,
  ResetButton,
  ToggleButton,
  ZoomControlsContainer,
  ZoomInButton,
  ZoomOutButton,
} from "./Mapbox.style";
import CustomPopup from "./DirectionPopup";
import { Icon } from "assets/Icons_";
import { Box, IconButton, Modal } from "@mui/material";

function Mapbox() {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const directionsRef = useRef(null);
  const [isSatelliteView, setIsSatelliteView] = useState(false);
  const [fetchedPOIs, setFetchedPOIs] = useState(new Set());
  const originalCenter = useRef([0, 0]);
  const originalZoom = useRef(0);
  const previousBounds = useRef(null); // Store previous bounds
  const zoomThreshold = 12; // Define zoom threshold for markers visibility
  const allMarkers = [];

  const handleFullScreen = () => {
    const mapContainer = mapContainerRef.current;

    if (mapContainer) {
      if (!document.fullscreenElement) {
        // Request full-screen mode
        mapContainer.requestFullscreen().catch((err) => {
          console.error("Error attempting to enable full-screen mode:", err);
        });
      } else {
        // Exit full-screen mode
        document.exitFullscreen();
      }
    }
  };

  const zoomIn = () => {
    const map = mapRef.current;
    if (map) {
      map.zoomIn();
    }
  };

  // Zoom Out function
  const zoomOut = () => {
    const map = mapRef.current;
    if (map) {
      map.zoomOut();
    }
  };

  const toggleMarkersVisibility = (map) => {
    const currentZoom = map.getZoom();
    allMarkers.current.forEach((marker) => {
      const markerElement = marker.getElement();
      if (currentZoom >= zoomThreshold) {
        markerElement.style.display = "block";
      } else {
        markerElement.style.display = "none";
      }
    });
  };

  useEffect(() => {
    const fetchPOIData = async (bbox, category) => {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${category}.json?bbox=${bbox.join(
        ","
      )}&access_token=${mapboxgl.accessToken}`;
      const response = await fetch(url);
      const data = await response.json();

      return data.features;
    };

    const categoryIcons = {
      supermarket: supermarketicon,
      cafe: cafe,
      bus_station: busStation,
      metro_station: metroStation,
      train_station: trainStation,
    };

    const addPOIMarkers = async (map, centerCoordinates) => {
      const categories = [
        "supermarket",
        "cafe",
        "metro_station",
        "bus_station",
        "train_station",
      ];
      const bounds = map.getBounds();
      const bbox = [
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
      ];
      // const zoomThreshold = 12;
      if (previousBounds.current && previousBounds.current.equals(bounds)) {
        return; // Skip if the bounds haven't changed
      }

      const addedCoordinates = new Set(); // Track added coordinates

      const createMarkers = async () => {
        categories.forEach(async (category) => {
          const pois = await fetchPOIData(bbox, category);
          pois.forEach((poi) => {
            const coordinatesString = poi.geometry.coordinates.join(",");
            // Skip adding if the same coordinates have been processed
            if (addedCoordinates.has(coordinatesString)) {
              return; // Skip this POI as its coordinates have already been added
            }
            addedCoordinates.add(coordinatesString); // Add coordinates to set
            // setFetchedPOIs((prev) => new Set(prev).add(coordinatesString));
            const popup = new mapboxgl.Popup({ offset: 25 });

            const el = document.createElement("div");
            el.className = "poi-marker";
            el.style.width = "30px";
            el.style.height = "30px";
            el.style.backgroundColor = "white";
            el.style.borderRadius = "50%";
            el.style.display = "flex";
            el.style.justifyContent = "center";
            el.style.alignItems = "center";
            el.style.transition = "box-shadow 0.2s ease-in-out";
            el.style.cursor = "pointer";

            const icon = document.createElement("div");
            icon.style.backgroundImage = `url(${categoryIcons[category]})`;
            icon.style.width = "20px";
            icon.style.height = "20px";
            icon.style.backgroundSize = "contain";
            icon.style.backgroundRepeat = "no-repeat";

            el.appendChild(icon);

            const poiMarker = new mapboxgl.Marker(el)
              .setLngLat(poi.geometry.coordinates)
              .setPopup(popup);

            allMarkers.push(poiMarker);

            // Initially add marker if zoom level is above threshold
            if (map.getZoom() >= zoomThreshold) {
              poiMarker.addTo(map);
            }

            // Handle focus and blur effects
            const handleFocus = () => {
              el.style.boxShadow = "0 0 0 2.5px green";
            };

            const handleBlur = () => {
              el.style.boxShadow = "none";
            };

            el.addEventListener("click", async () => {
              handleFocus();
              const origin = centerCoordinates;
              const destination = poi.geometry.coordinates;

              // Fetch route data for driving, walking, and cycling
              const [drivingResponse, walkingResponse, cyclingResponse] =
                await Promise.all([
                  fetch(
                    `https://api.mapbox.com/directions/v5/mapbox/driving/${origin[0]},${origin[1]};${destination[0]},${destination[1]}?access_token=${mapboxgl.accessToken}`
                  ),
                  fetch(
                    `https://api.mapbox.com/directions/v5/mapbox/walking/${origin[0]},${origin[1]};${destination[0]},${destination[1]}?access_token=${mapboxgl.accessToken}`
                  ),
                  fetch(
                    `https://api.mapbox.com/directions/v5/mapbox/cycling/${origin[0]},${origin[1]};${destination[0]},${destination[1]}?access_token=${mapboxgl.accessToken}`
                  ),
                ]);

              const drivingData = await drivingResponse.json();
              const walkingData = await walkingResponse.json();
              const cyclingData = await cyclingResponse.json();

              if (
                drivingData.routes.length > 0 &&
                walkingData.routes.length > 0 &&
                cyclingData.routes.length > 0
              ) {
                const drivingDuration = Math.round(
                  drivingData.routes[0].duration / 60
                );
                const walkingDuration = Math.round(
                  walkingData.routes[0].duration / 60
                );
                const cyclingDuration = Math.round(
                  cyclingData.routes[0].duration / 60
                );

                const popupContent = document.createElement(
                  "mapboxgl-popup-content"
                );

                // popupContent.classList.add("mapboxgl-popup-content");

                // Customize the popupContent div if needed
                // popupContent.style.width = "425px";
                // popupContent.style.background = "#FFFFFFbf";
                // popupContent.style.borderRadius = "20px";
                // popupContent.style.padding = "0px";

                // Use createRoot to render the React component inside the popup content
                const root = createRoot(popupContent);
                root.render(
                  <CustomPopup
                    clr={clr}
                    poi={poi}
                    walkingDuration={walkingDuration}
                    cyclingDuration={cyclingDuration}
                    drivingDuration={drivingDuration}
                  />
                );

                // Set the popup content with the React component
                popup.setDOMContent(popupContent);

                directionsRef.current.setOrigin(origin);
                directionsRef.current.setDestination(destination);
              }
            });

            // document.head.appendChild(style);

            // Reset routes and handle box-shadow on popup close
            popup.on("close", () => {
              directionsRef.current.removeRoutes();
              handleBlur();
              map.flyTo({
                // center: [12.59311, 55.68255],
                bearing: -64,
                pitch: 15,
                // zoom: 16,
                speed: 0.8,
                curve: 1.5,
                easing: (t) => t,
              });
            });
          });
        });
      };

      map.on(
        "load",
        debounce(() => {
          createMarkers();
        }, 700) // Debounce for 300ms
      );
      map.on(
        "moveend",
        debounce(() => {
          createMarkers();
        }, 800) // Debounce for 300ms
      );

      //   map.on("zoom", debounce(() => {
      //     const currentZoom = map.getZoom();
      //     allMarkers.forEach((marker) => {
      //       if (currentZoom >= zoomThreshold) {
      //         if (!marker.getElement().parentNode) {
      //           marker.addTo(map);
      //         }
      //       } else {
      //         marker.remove();
      //       }
      //     });
      //   },500));
      // };
      map.on(
        "zoom",
        debounce(() => {
          toggleMarkersVisibility(map, zoomThreshold);
        }, 300)
      );
    };

    const initializeMap = async () => {
      try {
        const response = await fetch(
          "https://pratik.dev.api.estatetool.net/v1/projects/15"
        );
        const data = await response.json();

        const mapboxToken = data.MapboxConfig[0].Token;
        const styleURL = data?.MapboxConfig[0]?.Style;
        const satelliteStyleURL = "mapbox://styles/mapbox/satellite-v9";
        const centerCoordinates =
          data.MapboxConfig[0].MapCenter.split(",").map(Number);
        const zoomLevel = data.MapboxConfig[0].Zoom;
        const bearing = data.MapboxConfig[0].Bearing;
        const pitch = data.MapboxConfig[0].Pitch;

        mapboxgl.accessToken = mapboxToken;

        const map = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: isSatelliteView ? satelliteStyleURL : styleURL,
          center: centerCoordinates,
          zoom: zoomLevel,
          bearing: bearing,
          pitch: pitch,
          attributionControl: false,
          minZoom: 9,
          maxZoom: 20,
        });

        originalCenter.current = centerCoordinates;
        originalZoom.current = zoomLevel;
        mapRef.current = map;

        const el = document.createElement("div");
        el.className = "main-marker";
        el.style.width = "70px";
        el.style.height = "70px";
        el.style.backgroundImage = `url(${mainMarkerIcon})`;
        el.style.backgroundSize = "contain";
        el.style.backgroundRepeat = "no-repeat";
        el.style.cursor = "pointer";
        el.style.zIndex = "100";

        const mainMarker = new mapboxgl.Marker(el)
          .setLngLat(centerCoordinates)
          .addTo(map);

        const directions = new MapboxDirections({
          accessToken: mapboxgl.accessToken,
          unit: "metric",
          profile: "mapbox/driving",
          interactive: false,
          controls: { inputs: false, instructions: false }, // Hide inputs and instructions
          // _markers: false, // This hides the default A and B markers
        });

        directions.on("route", () => {
          const markerB = directions._getMarker("B"); // Get the destination marker

          if (markerB) {
            markerB.remove(); // Remove the destination marker only
          }
        });

        directionsRef.current = directions;
        map.addControl(directions);

        map.addControl(new mapboxgl.NavigationControl());

        // map.on("load", () => {
        //   addPOIMarkers(map, centerCoordinates);
        // });
        map.on(
          "load",
          debounce(() => {
            addPOIMarkers(map, centerCoordinates);
          }, 400) // Debounce for 300ms
        );
        // map.on("moveend", () => {
        //   addPOIMarkers(map, centerCoordinates);
        // });
        map.on(
          "moveend",
          debounce(() => {
            addPOIMarkers(map, centerCoordinates);
          }, 700) // Debounce for 300ms
        );
      } catch (error) {
        console.error("Error fetching the API:", error);
      }
    };

    initializeMap();

    return () => {
      if (mapContainerRef.current) mapContainerRef.current.innerHTML = "";
    };
  }, [isSatelliteView]);

  const toggleMapStyle = () => {
    setIsSatelliteView(!isSatelliteView);
  };

  const resetMap = () => {
    const map = mapRef.current;
    const directions = directionsRef.current;
    if (map && directions) {
      map.flyTo({
        center: originalCenter.current,
        bearing: -64,
        pitch: 15,
        zoom: 17,
      });

      directions.removeRoutes();
    }
  };

  return (
    <>
      <Container ref={mapContainerRef} />
      <ButtonsContainer>
        <ResetButton onClick={resetMap}>
          <div
            style={{
              position: "relative", // Allows you to position elements on top of each other
              width: "40px", // Adjust to fit your icon size
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* White circle above the icon */}
            <div
              style={{
                position: "absolute", // Positioned relative to the parent div
                backgroundColor: "#FFF",
                borderRadius: "50%",
                top: "9px",
                width: "15px", // Adjust size for the white circle
                height: "15px",
                zIndex: 1, // Ensures this is above the icon
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "2.5px 2.5px",
              }}
            >
              {/* Inner icon inside the white circle */}
              <Icon size={"sm"} icon="inside-house" color={clr?.primary} />
            </div>

            {/* The main reset icon */}
            <Icon
              size={"xl"} // Main icon size, adjusted to fit with the circle
              icon="reset-button"
              color={clr?.primary}
            />
          </div>
        </ResetButton>
        <ToggleButton onClick={toggleMapStyle} isSatellite={isSatelliteView}>
          {" "}
          <img
            src={isSatelliteView ? mapIcon : satelliteIcon}
            alt="Toggle Map Style"
          />
        </ToggleButton>
        {/* <FullScreenButton onClick={handleFullScreen}>
<Icon size={"xl"} icon="full-screen" color={"#000"} />
</FullScreenButton> */}
        
        <ZoomControlsContainer>
          <ZoomInButton onClick={zoomIn}>
            <Icon size={"xl"} icon="plus-icon" color={"#000"} />
          </ZoomInButton>
          <ZoomOutButton onClick={zoomOut}>
            <Icon size={"xl"} icon="minus-icon" color={"#000"} />
          </ZoomOutButton>
        </ZoomControlsContainer>
      </ButtonsContainer>
    </>
  );
}

export default Mapbox;
