import styled from "styled-components";
import { KIOSK } from "constants";

export const PoweredBy = ({ mode, clr = "#000", padding = "16px 0 16px 0" }) => {
  const href = "https://estatetool.dk";
  const style = { color: clr };
  const link = KIOSK ? (
    "estatetool"
  ) : (
    <a href={href} target="_blank" style={style}>
      <span>estatetool</span>
    </a>
  );

  return (
    <Powered clr={clr} mode={mode?.toString()} padding={padding}>
      <span className="eus-credits">
        Powered by {link} | &#169; {new Date().getFullYear()}
      </span>
    </Powered>
  );
};

const Powered = styled.div`
  position: ${({ mode }) => (mode === "true" ? "unset" : "initial")};
  bottom: 10px;
  width: 100%;
  color: ${({ clr }) => clr};
  text-align: center;
  padding: ${({ padding }) => padding};
  white-space: nowrap;
  font-size: 0.75rem;
`;
