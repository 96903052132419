import { Box } from "@mui/material";
import { FlexBox, FooterButton, FullWidth, Section } from "./DualMenu.style";
import { useBreak } from "hooks/useBreak";
import { useLocation, useNavigate } from "react-router-dom";
import { getFocus, useAtom, useGet } from "state/jotai";
import { _bootstrap } from "state/store.bootstrap";
import { Icon } from "assets/Icons_";
import { SelectFunction } from "components/SelectFunction/SelectFunction";
import { ShiftProperty } from "components/ShiftProperty/ShiftProperty";
import { Controls } from "components/Isometry/Isometry.Controls";
import { getIsoData, setIsoData } from "state/jotai.isometryDataHooks";
import { Status } from "components/StatusPopup/StatusPopup";
import { useTranslation } from "react-i18next";
import { currentActiveProject, params_query } from "state/store.global";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { ArrowButton } from "components/Isometry/Isometry.Controls.Arrows";
import EusButton, { ETStyledButton } from "components/Button/EusButton";

export const IsometryDualMenu = ({
  left,
  right,
  nav,
  pin,
  openSelectFunction,
  showSelectFunction,
  setShowSelectFunction,
  clickList,
  list,
  buttonsLabel,
  showProperty,
  setShowProperty,
  toggleDrawer,
  config,
  rotatemode,
  click2,
  turnBuilding,
  isrotate,
  click1,
  clickModel,
  model,
  filterShouldShow,
  clickFilter,
  filter,
  state,
  setState,
  closeDrawer,
  setShowFilter,
  setShowMenu,
}) => {
  const type = getFocus(_bootstrap, "type");
  const navigate = useNavigate();
  const params = useGet(params_query);
  const names = getFocus(_bootstrap, "projectnames");
  const {
    i18n: { language },
  } = useTranslation();
  const isStatus = true;
  const isMobile = useBreak("md_dn");
  const here = useLocation().pathname;
  const isIsometry = here.includes("isometry");

  const frame = getIsoData("activeframe");
  const frames = getIsoData("frames");
  const index = getIsoData("snapindex");
  const setIndex = setIsoData("snapindex");
  const setFrame = setIsoData("activeframe");
  const percent = getIsoData("percent");
  const setActiveProject = useAtom(currentActiveProject)[1];

  const showButton = [
    "list",
    "compare",
    "favorites",
    "gallery",
    "downloads",
  ].some((i) => here.includes(i));

  const props = { type, isIsometry, pin, isMobile };
  return (
    <>
      <FullWidth
        isMobile={isMobile}
        left={left}
        right={right}
        nav={nav}
        clr={clr}
        isBgTrue={true}
      >
        <FlexBox clr={clr} isIsometry={isIsometry}>
          {isMobile ? (
            <>
              {type === "poly" && pin > 0 && rotatemode ? (
                <FooterButton
                  padding="0 30px"
                  {...props}
                  clr={clr}
                  onClick={rotatemode && isrotate ? click1 : click2}
                  variant={"primary"}
                >
                  <Icon
                    icon={isrotate ? "pause" : "play"}
                    color={clr?.primary_text}
                    isFaIcon={true}
                    size="xl"
                  />{" "}
                  <span
                    className="eus-button-2"
                    style={{ color: clr?.primary_text }}
                  >
                    {isrotate ? "play rotation" : "Start rotation"}
                  </span>
                </FooterButton>
              ) : type === "mono" && turnBuilding ? (
                <FooterButton
                  padding="0 30px"
                  {...props}
                  clr={clr}
                  onClick={rotatemode && isrotate ? click1 : click2}
                  variant={"primary"}
                >
                  <Icon
                    icon={isrotate ? "pause" : "play"}
                    color={clr?.primary_text}
                    isFaIcon={true}
                    size="xl"
                  />{" "}
                  <span
                    className="eus-button-2"
                    style={{ color: clr?.primary_text }}
                  >
                    {isrotate ? "Stop rotation" : "Start rotation"}
                  </span>
                </FooterButton>
              ) : (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: isIsometry
                        ? "flex-start"
                        : "space-between",
                      gap: "8px",
                      position: "relative",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    {/* mobile menu */}
                    { (
                      <ArrowButton
                        variant={"primary"}
                        clr={clr}
                        onClick={openSelectFunction}
                        padding="18px"
                      >
                        <span
                          style={{
                            fontSize: "23px", // This will enlarge the icon
                            display: "inline-flex", // Ensures proper alignment
                          }}
                        >
                          <Icon
                            icon="ellipsisvertical"
                            color={clr.primary_text}
                            isFaIcon={true}
                            size="xl"
                            wrap     = {{ transform: showSelectFunction ? 'rotate(90deg)' : 'rotate(0deg)'}}
                          />
                        </span>
                        <SelectFunction
                          showSelectFunction={showSelectFunction}
                          setShowSelectFunction={setShowSelectFunction}
                        />
                      </ArrowButton>
                    )}
                    {isIsometry ? (
                      <EusButton
                        {...props}
                        clr={clr}
                        active={here.includes("list")}
                        onClick={clickList}
                        variant={"primary"}
                        iconName={"list"}
                        icon={true}
                        buttonText="Boligliste"
                        buttonlabel={true}
                        iconSize={"xl"}
                        isFaIcon={true}
                        padding={"12px 40px"}
                      />
                    ) : type === "poly" && pin === 0 ? (
                      <Section
                        padding={"12px 40px"}
                        pin={pin}
                        clr={clr}
                        showButton={showButton}
                        active={true}
                        onClick={clickModel}
                      >
                        <Icon
                          size={"lg"}
                          color={clr?.primary_text}
                          icon="modelover"
                          isFaIcon={true}
                        />

                        <div
                          className="eus-button-2"
                          style={{ color: clr?.primary_text }}
                        >
                          Oversigtskort
                        </div>
                      </Section>
                    ) : (
                      <EusButton
                        pin={pin}
                        clr={clr}
                        showButton={showButton}
                        active={true}
                        onClick={clickModel}
                        variant={"primary"}
                        iconName={"model"}
                        icon={true}
                        buttonText="isometri"
                        buttonlabel={true}
                        iconSize={"25"}
                        isFaIcon={false}
                      />
                    )}
                  </Box>
                  {isIsometry && (
                    <Box>
                      <Section width="none">
                        {!rotatemode && percent == 100 && (
                          <>
                            {config?.IMAGE_SNAPFRAMES?.length > 1 && (
                              <Controls
                                {...{
                                  frame,
                                  setFrame,
                                  config,
                                  frames,
                                  index,
                                  setIndex,
                                }}
                              />
                            )}
                          </>
                        )}
                      </Section>
                    </Box>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              <Box
                sx={{
                  width: isMobile && !isIsometry ? "100%" : "auto",
                  display: "flex",
                  justifyContent:
                    isMobile && !isIsometry ? "space-between" : "flex-start",
                  gap: "16px",
                  position: "relative",
                }}
              >
                {turnBuilding ? (
                  <>
                    <EusButton
                      {...props}
                      clr={clr}
                      onClick={rotatemode && isrotate ? click1 : click2}
                      variant={"primary"}
                      icon={true}
                      iconName={isrotate ? "pause" : "play"}
                      isFaIcon={true}
                      buttonlabel={false}
                      otherText={isrotate ? "Stop rotation" : "Start rotation"}
                      iconSize={"xl"}
                    />
                  </>
                ) : (
                  <>
                    {isIsometry ? (
                      <EusButton
                        {...props}
                        clr={clr}
                        active={here.includes("list")}
                        onClick={clickList}
                        variant={"primary"}
                        iconName={"list"}
                        icon={true}
                        buttonText="Boligliste"
                        buttonlabel={true}
                        iconSize={"xl"}
                        isFaIcon={true}
                      />
                    ) : // <></>
                    type === "poly" && pin === 0 ? (
                      <Section
                        padding={"12px 40px"}
                        pin={pin}
                        clr={clr}
                        showButton={showButton}
                        active={true}
                        onClick={clickModel}
                      >
                        <Icon
                          size={"lg"}
                          color={clr?.primary_text}
                          icon="modelover"
                          isFaIcon={true}
                        />
                        <div
                          className="eus-button-2"
                          style={{ color: clr?.primary_text }}
                        >
                          Oversigtskort
                        </div>
                      </Section>
                    ) : (
                      <EusButton
                        pin={pin}
                        clr={clr}
                        showButton={showButton}
                        active={true}
                        onClick={clickModel}
                        variant={"primary"}
                        iconName={"model"}
                        icon={true}
                        buttonText="isometri"
                        buttonlabel={true}
                        iconSize={"25"}
                        isFaIcon={false}
                      />
                    )}
                    {filterShouldShow && !isMobile && (
                      <EusButton
                        iconName={"filters"}
                        icon={true}
                        clr={clr}
                        buttonText="Anvendfilter"
                        buttonlabel={true}
                        onClick={clickFilter}
                        variant={"primary"}
                        pin={pin}
                        iconSize={"xl"}
                        showButton={showButton}
                        isFaIcon={true}
                      />
                    )}

                    {/* UnComment this whenever we have all property on else comment ShiftProperty component section */}
                    {type === "poly" && pin > 0 && (
                      <Section
                        pin={pin}
                        clr={clr}
                        showButton={showButton}
                        active={true}
                        onClick={() => {
                          closeDrawer("detail");
                          setShowFilter(false);
                          setShowMenu(false);
                          setActiveProject("");
                          navigate(
                            type == "mono"
                              ? `/${language}/isometry${
                                  params.length ? params : ""
                                }`
                              : `/${language + names[language][0].path}${
                                  params.length ? params : ""
                                }`
                          );
                        }}
                      >
                        <Icon
                          size={"lg"}
                          color={clr?.primary_text}
                          icon="modelover"
                          isFaIcon={true}
                        />
                        <div
                          className="eus-button-2"
                          style={{ color: clr?.primary_text }}
                        >
                          Oversigtskort
                        </div>
                      </Section>
                    )}

                    {/* remove this pin when we have property more than 1 */}

                    {type === "poly" && (
                      <Section
                        pin={pin}
                        onClick={() => {
                          setShowProperty(!showProperty);
                          toggleDrawer();
                        }}
                        clr={clr}
                        showButton={showButton}
                      >
                        <Icon
                          icon="buildings"
                          size="lg"
                          color={
                            pin > 0 || showButton ? "#fff" : clr?.primary_text
                          }
                          isFaIcon={true}
                        />
                        <div
                          className="eus-button-2"
                          style={{ color: clr?.primary_text }}
                        >
                          Skift ejendom
                        </div>
                        <ShiftProperty
                          showProperty={showProperty}
                          setShowProperty={setShowProperty}
                        />
                      </Section>
                    )}

                    {type === "poly" && pin !== 0 && (
                      <Section
                        pin={pin}
                        clr={clr}
                        onClick={() => isMobile && setState(!state)}
                        onMouseEnter={() => !isMobile && setState(true)}
                        onMouseLeave={() => !isMobile && setState(false)}
                      >
                        <Status
                          pin={pin}
                          state={state}
                          setState={setState}
                          isMobile={isMobile}
                          {...{ percent }}
                        />
                      </Section>
                    )}
                    {type === "mono" && (
                      <ETStyledButton
                        isStatus={isStatus}
                        pin={pin}
                        clr={clr}
                        onClick={() => isMobile && setState(!state)}
                        onMouseEnter={() => !isMobile && setState(true)}
                        onMouseLeave={() => !isMobile && setState(false)}
                        variant={isMobile ? "secondary" : "primary"}
                      >
                        <Status
                          pin={pin}
                          state={state}
                          setState={setState}
                          isMobile={isMobile}
                          {...{ percent }}
                        />
                      </ETStyledButton>
                    )}
                    {config?.IMAGE_SNAPFRAMES?.length > 0 &&
                      isIsometry &&
                      !isMobile && (
                        <EusButton
                          {...props}
                          clr={clr}
                          onClick={rotatemode && isrotate ? click1 : click2}
                          variant={"primary"}
                          iconName={
                            !turnBuilding
                              ? "rightarrowrotate"
                              : `${isrotate ? "pause" : "play"}`
                          }
                          icon={true}
                          buttonText={
                            !turnBuilding
                              ? "Drejbygning"
                              : `${
                                  isrotate ? "Stop rotation" : "Start rotation"
                                }`
                          }
                          buttonlabel={true}
                          iconSize={"xl"}
                          isFaIcon={true}
                        />
                      )}
                  </>
                )}
              </Box>
              {!turnBuilding && isIsometry && (
                <Box>
                  <Section width="none">
                    {!rotatemode && percent == 100 && (
                      <>
                        {config?.IMAGE_SNAPFRAMES?.length > 1 && (
                          <Controls
                            {...{
                              frame,
                              setFrame,
                              config,
                              frames,
                              index,
                              setIndex,
                            }}
                          />
                        )}
                      </>
                    )}
                  </Section>
                </Box>
              )}
            </>
          )}
        </FlexBox>
        <PoweredBy clr="#FFF" padding={ isMobile ? "0px 0 16px 0" : "0px 0 8px 0"} />
      </FullWidth>
    </>
  );
};
