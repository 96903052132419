import { Box, Button, Divider, Drawer, FormControl, FormControlLabel, Grid, IconButton, MenuItem, OutlinedInput, Radio, RadioGroup, useMediaQuery } from "@mui/material";
import { Icon } from "components/Icons";
import React, { useEffect, useState } from "react";
import ContactForm from "./ContactForm";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useLeadDrawer } from "./useLeadDrawer";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { TESTEMAIL } from "api/api";
import { CustomInput, CustomLabel, CustomSelect, PlaceHolderInputField  } from "./styled-components";
import { useFetch } from "hooks/fetch/useFetch";
import axios from "axios";
import { motion } from "framer-motion";
import SuccessMessage from "./SuccessMessage";
import { useOpenHouseData } from "components/OpenHouseBanner/OpenHouseBanner.Data";
import { PhoneNumberDropdown } from "components/AdvanceDropdown/PhoneNumberDropdown/Dropdown";
import { ReactSelect } from "components/AdvanceDropdown/NormalDropdown/Dropdown";
import { CheckBox } from "components/FormComponent/CheckBox";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { useLocation } from "react-router-dom";
import { SearchDropdown } from "components/AdvanceDropdown/SeachDropdown/Dropdown";
import { CustomPlaceHolderInputField } from "components/FormComponent/TextField";
import { CustomButton } from "components/Unit/NewReservationButton";
import { enqueueSnackbar } from "notistack";
import EusButton from "components/Button/EusButton";

const LeadDrawer = () => {
  const { ready, MM, MMM, Sitename, Time, Description, Address } = useOpenHouseData();
  const sm       = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const here = useLocation().pathname;
  const atUnit = here.includes("unit");
  
  const [submitted, setSubmitted] = useState(false);
  
  const [checked, setChecked] = useState(false);
  const [personName, setPersonName] = useState([]);
  const [nationName, setNationName] = useState([]);
  const [describeName, setDescribeName] = useState([]);
  const grid = false
  const { isOpen, toggle, unit } = useLeadDrawer();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { i18n: { language } } = useTranslation();
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
  const contactData  = getFocus(_project, 'ContactProperties')?.[pin]?.[pid] ?? []

  const testLeadData = useFetch({ url: TESTEMAIL(language) });

  const unitNo = unit?.getValue("Projeknr") ? `${buttonsLabel?.find(btn => btn?.UILabelProperty === 'Bolignr')?.Label} ${unit.getValue("Projeknr")},` : "";
  const unitId = unit?.getId();
  const unitAddress = unit?.getAddress() ?? "";

  const { register, handleSubmit, reset, control, formState: { errors, isValid }, } = useForm({ mode: "onChange", });
  const homeUrl = 'https://estatetool.findapartmentaarhus.unity-living.com';
  const unitUrl = unit?.getUrl(`${homeUrl}/${language}`) ?? "";
  const onSubmit = (data) => {
    handleFormSubmit(data);
  };

  const handleFormSubmit = async (data) => {
    const DescribeIDMapping = {
      'Studerende': '1',
      'Student': '1',
      'Young profesional': '2',
      'Young Professional': '2',
      'Expat': '3',
      'Employee / Fuldtidsansat': '4',
      'B2B': '5',
      'Andet': '6',
      'Other': '6',
      'Personlig': '7',
      'Personal viewing': '7',
      'Digital': '8',
      'Personlig fremvisning': '7',
      'Digital presentation': '8',
      'Digital fremvisining': '8',
      'Traveling Professional': '9',
      'Digital viewing': '8',
      'Tidsbegrænset lejer': '11',
      'Temporary Stay': '11'
  };

  const utms = {
    utm_source: "google",
    utm_medium: "cpc",
    utm_campaign: "spring_sale",
    utm_content: "ad_variation_1"
  }

  let utmParam = {};
  for (let [key, value] of new URLSearchParams(location.search).entries()) {
    if(key === "utm_source" || key === "utm_medium" || key === "utm_campaign" || key === "utm_content") {
      utmParam[key] = value;
    }
  }

  const DANISH_CAMPAIGN = "b1051b467cc6c92f10922a80fc340ef2"
  const ENGLISH_CAMPAIGN = "a12aa96ce33d7ccb05506f62b34f4869"

  // const leadNationality = testLeadData?.D?.countries?.filter((c) => c?.name_dk === data?.nationality_id || c?.name_eng === data?.nationality_id)
  // const leadCountry_code = testLeadData?.D?.countries?.filter((c) => c?.country_code === `+${data?.country_id.split("+")?.[1]}`)

  const leadNationality = data?.nationality_id?.country_id
  const leadCountry_code = data?.country_id?.country_id

      const payload = new URLSearchParams({
        Token: testLeadData?.D?.token,
        Hash: testLeadData?.D?.hash,
        Campaign: "9ab5fe5aa77030cf30f9df9b6754d8ff",
        B_ID: unitId,
        Fornavne: data.firstname,
        Efternavn: data.lastname,
        Email_privat: data.email,
        country_code: leadNationality,
        nationality_code: leadCountry_code,
        SELECTIONS: `${DescribeIDMapping[data?.describe_id.value] || 0},0,0,0`, 
        Telefon_mobil: data.phone,
        Comments: "",
        ...utmParam
      });
      // console.log(Object.fromEntries(payload))

      // enqueueSnackbar(variant ? language === "da" ? "Emailen er blevet sendt" : "The email has been sent" : language === "da" ? 'Fejl ved indsendelse af formular' : 'Error submitting form', { variant: variant ? 'success' : 'error' });


      axios.post('https://show.estatetool.net/api2/post_leadtool/', payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((data) => {
        setSubmitted(Object.keys(data || {}).length ? true : false);
        testLeadData.mutate()
        setPersonName([]);
        setNationName([]);
        setDescribeName([])
        reset();
        setChecked(false);
      }).catch((err) => {
        enqueueSnackbar(language === "da" ? 'Noget gik galt. Prøv igen' : 'Something went wrong. Try again', { variant: 'error' });
      });
  };

  
  useEffect(()=> {
    testLeadData.mutate()
  }, [language])

  useEffect(()=> {
    setSubmitted(false)
    setPersonName([]);
    setNationName([]);
    setDescribeName([])
    reset();
    setChecked(false);
    testLeadData.mutate()
  }, [isOpen])

  const handleNumberInput = (event) => {
    return (event.key.charCodeAt() >= 48 && event.key.charCodeAt() <= 57) || event.key === 'Backspace' ? event.key : event.preventDefault();
  };

  
  const handleChange = (event) => {
    setPersonName(event.target.value);
  };


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: !grid ? "right" : "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: !grid ? "right" : "left"
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
      },
    },
  };

  const describeOption = {
    'da': [
      "Studerende",
      "Young Professional",
      "Traveling Professional",
      "Expat",
      "B2B",
      "Tidsbegrænset lejer",
      "Andet"
    ],
    'en': [
      "Student",
      "Young Professional",
      "Traveling Professional",
      "Expat",
      "B2B",
      "Temporary Stay",
      "Other",
    ]
  }
  
  const handleCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Drawer
      hideBackdrop={true}
      anchor={isMobile ? "bottom" : "right"}
      open={isOpen}
      onClose={() => toggle()}
      PaperProps={{ sx: { scrollbarWidth: "none", "&::-webkit-scrollbar": { display: "none" }, color: "secondary.contastText", backgroundColor: "secondary.main", height: {lg: `calc(100% - 160px - ${atUnit || isMobile ? "-90px" : "0px"})`}, top: "70px", justifyContent: "space-between" , boxShadow: 'unset'}, paddingBottom: { xs:"16px", lg: 0 } }}
      sx={{ "&.MuiDrawer-root": { width: 450, top: "70px", right: 0, left: 'unset', bottom: 'unset' } }}
    >
      <IconButton
          onClick={toggle}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            zIndex: 10
          }}
        >
          <span className="icon-hover-effect" >
          <Icon 
            icon="close"
            sx={{
              fontSize: 18,
            }}
          />
          </span>
        </IconButton>
      <>
      {submitted ? (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          style={{ height: "100%", display: "flex", alignItems: "center" }}
        >
             <SuccessMessage label={false} msg={ language === 'da' ? `<div style={{ fontFamily: "Cerebri sans" }}><p>Tak for din tilmelding til åbent hus.<br/>  Vi glæder os til at se dig d. ${MM} ${MMM}.</p></div>` : `<div style={{ fontFamily: "Cerebri sans !important" }}><p>Thank you for registering for the open house.<br/> We look forward to seeing you on ${MM} ${MMM}.</p></div>`} width={ isMobile ? "100%" : 450}/>
        </motion.div>
      ) :
      <>  
      <Box sx={{ width: isMobile ? "auto" : 450, position: "relative", pt: 5, pb: 4, px: 3, lineHeight: 1.3, }} >
        <Box sx={{ lineHeight: 1.5, }} >
          <Box className="h2" sx={{mb:2}}>
            {/* {language === "da" ? "Bestil fremvisning" : "Book viewing"} */}
            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'OpenHouseBtnLabel')[0]?.Label }
            </Box>
          <Box sx={{ mb: 2 }}>
            <span className="paragraph1-regular">
              {/* {language === "da" ? "Udfyld venligst formularen, og en af de rigtige agenter vil kontakte dig via telefon eller e-mail så hurtigt som muligt" : "Please fill out the form and one of out real agents will contact you by phone or email as soon as possible" } */}
              { buttonsLabel?.filter( btn => btn.UILabelProperty === 'OpenHouseHeading')[0]?.Label } { MM } { MMM }
            </span>
          </Box>
        </Box>

        <form onSubmit={handleSubmit(onSubmit)} style={{ background: '#ffffffe6', borderRadius: '6px', padding: '16px', marginTop: '40px' }} >
              <Grid
                container
                columnSpacing={3}
                rowSpacing={grid ? 2 : 1}
                sx={{ }}
              >
                  <Grid item xs={12}>
                <Grid sx={{ gap: "16px" }}>
                  <FormControl
                    sx={{ mb: 2, gap: '8px' }}
                    fullWidth
                    variant='filled'
                  >
                    <CustomLabel shrink  className="h5">
                    {buttonsLabel?.filter( btn => btn.UILabelProperty === 'FullName')[0]?.Label}&nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomPlaceHolderInputField 
                     error={errors.firstname ? true : false}
                     helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'firstNameErrorWarn')[0]?.Label}
                    label={buttonsLabel?.filter( btn => btn.UILabelProperty === 'FirstMiddleName')[0]?.Label}
                    register={{...register('firstname', { required: true, validate: value => value.trim() !== "" })}}
                    />
                  </FormControl>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    {/* <CustomLabel shrink  className="h5">{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEfternavn')[0]?.Label } &nbsp;<span>*</span></CustomLabel> */}
                    <CustomPlaceHolderInputField  
                       helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'lastNameErrorWarn')[0]?.Label}
                       error={errors.lastname ? true : false} 
                    label={buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEfternavn')[0]?.Label}
                    register={{...register('lastname', { required: true, validate: value => value.trim() !== "" })}}
                    />
                  </FormControl>
                </Grid>
                </Grid>
                <Grid item xs={12} sx={{ pt: "0 !important" }}>
                <Grid sx={{ pt: "0 !important" }}>
                  <FormControl
                    sx={{ mb: '25px', gap: '8px' }}
                    fullWidth
                    variant='filled'
                    error={errors.email ? true : false}
                  >
                    <CustomLabel shrink  className="h5">
                      {buttonsLabel?.filter( btn => btn.UILabelProperty === 'PrivateEmail')[0]?.Label}&nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomPlaceHolderInputField
                      error={errors.email ? true : false}
                      helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'emailAddressErrorWarn')[0]?.Label}
                      label={buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEmail')[0]?.Label}
                      register={{...register('email', { required: true,  validate: (value) => {
                        const trimmedValue = value.trim();  // Trim spaces before and after the email
                        if (!trimmedValue) {
                          return false
                        }
                        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                        if (!emailPattern.test(trimmedValue)) {
                          return false
                        }
                        return true;
                      } })}}
                    />
                  </FormControl>
                </Grid>
                </Grid>
                <Grid item xs={12} sx={{ pt: "0 !important" }}>
                <Grid sx={{ gap: "16px" }}>
                <FormControl
                    sx={{ mb: 3, gap: '8px' }}
                    fullWidth
                    variant='filled'
                    error={errors.country_id || errors.phone ? true : false}
                  >
                    <CustomLabel shrink  className="h5">
                      {buttonsLabel?.filter( btn => btn.UILabelProperty === 'PhoneNumber')[0]?.Label}&nbsp;<span>*</span>
                    </CustomLabel>
                    <PhoneNumberDropdown error={errors.country_id || errors.phone ? true : false} options={testLeadData?.D?.countries} helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'dropdownErrorWarn')[0]?.Label} control={control} getPhoneId={'country_id'} getPhoneNumber={'phone'} placeholder={ buttonsLabel?.filter( btn => btn.UILabelProperty === 'Country')[0]?.Label } description={buttonsLabel?.filter( btn => btn.UILabelProperty === 'CountryCodeDropdownDescription')[0]?.Label} />
                  </FormControl>
                </Grid>
                </Grid>

                <Grid item xs={12}  sx={{ pt: "0 !important" }}>
                <FormControl
                    sx={{ mb: 3, gap: '8px' }}
                    fullWidth
                    variant='filled'
                  >
                    <CustomLabel shrink  className="h5">
                     { language === 'da' ? 'Nationalitet' : 'Nationality' }&nbsp;<span>*</span>
                    </CustomLabel>
                    <SearchDropdown
                    control={control}
                      fieldName={"nationality_id"}
                      helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'dropdownErrorWarn')[0]?.Label}
                      error={errors.nationality_id ? true : false}
                      description={buttonsLabel?.filter( btn => btn.UILabelProperty === 'NationalityDropdownDescription')[0]?.Label}
                      placeholder={buttonsLabel?.filter( btn => btn.UILabelProperty === 'chooseSelect2')[0]?.Label}
                      options={testLeadData?.D?.countries.map((cnty) => ({
                        ...cnty,
                        value: language === "da" ? cnty?.name_dk : cnty?.name_eng,
                        label: language === "da" ? cnty?.name_dk : cnty?.name_eng,
                      }))}
                      />
                  </FormControl>
                </Grid>
                
                <Grid item xs={12} sx={{ pt: "0 !important" }}>
                  <FormControl
                    sx={{ mb: 3, gap: '8px' }}
                    fullWidth
                    variant='filled'
                  >
                    <CustomLabel shrink  className="h5">
                    { buttonsLabel?.filter( btn => btn.UILabelProperty === 'formSelectQuestion3')[0]?.Label }&nbsp;<span>*</span>
                    </CustomLabel>
                    <Controller
                        control={control}
                        name="describe_id"
                        rules={{ required: true }}
                        render={({ field: { onChange, value, ref, error } }) => {
                          return (
                            <ReactSelect
                            error={error}
                            helperText={buttonsLabel?.filter( btn => btn.UILabelProperty === 'dropdownErrorWarn')[0]?.Label}
                                placeholder={<span className="paragraph2-light">{buttonsLabel?.filter( btn => btn.UILabelProperty === 'chooseSelect3')[0]?.Label}</span>}
                                inputRef={ref}
                                options={describeOption?.[language].map((desc) => ({
                                  value: desc,
                                  label: desc,
                                }))}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                            />
                        )
                        }}
                    />
                    {/* <CustomSelect
                        displayEmpty
                        value={describeName}
                        IconComponent={
                          ArrowDropDownIcon
                        }
                        onChange={(event) => {
                          setDescribeName(event.target.value)
                        }}
                        onClose={(e) =>  setTimeout(() => {
                          document.activeElement.blur();
                        }, 0)}
                        input={<OutlinedInput {...register('describe_id', { required: true, validate: value => value.trim() !== ""  })} placeholder="" />}
                        renderValue={(selected, i) => {
                          const en = describeOption.en.findIndex((en) => en === selected)
                          const da = describeOption.da.findIndex((da) => da === selected)
                          if (selected.length === 0) {
                            return <CustomLabel shrink  className="h5">
                            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'chooseSelect3')[0]?.Label }
                           </CustomLabel>
                          }
                          return describeOption?.[language][en > -1 ? en : da];
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="">
                          <CustomLabel shrink  className="h5">
                            { buttonsLabel?.filter( btn => btn.UILabelProperty === 'chooseSelect3')[0]?.Label }
                          </CustomLabel>
                        </MenuItem>
                        {describeOption?.[language]?.map((desc, index) => (
                          <MenuItem
                            key={`${desc} ${index}`}
                            value={desc}
                          >
                             { desc }
                          </MenuItem>
                        ))}
                      </CustomSelect> */}
                  </FormControl>
                </Grid>
              </Grid>

              <Box sx={{ mb: 3, display: "flex", flexDirection: "column", gap: "8px" }}>
                <CustomLabel className="h4">
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelser')[0]?.Label }&nbsp;<span>*</span>
                </CustomLabel>
                <FormControlLabel
                  sx={{
                    alignItems: 'center',
                    mr: 0,
                    ml: 0,
                    gap: '8px',
                    "span":{    color: '#000' },
                    "a":{color: `${'#000'} !important` },
                  }}
                  control={
                    <CheckBox
                      name='_value'
                      checked={checked}
                      onChange={handleCheckbox}
                      sx={{ p: 0 }}
                    />
                  }
                  label={
                    <Box >
                      <div>
                        <span className="paragraph2-light">{language === "en" ? "Accept our terms and conditions." : "Accepter vores vilkår og betingelser"}&nbsp;</span>
                        <a className="h5" style={{ textDecoration: 'none' }} href={"https://estatetool.dk/terms/"} target="_blank">{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'ReadMoreHere')[0]?.Label }</a>
                      </div>
                    </Box>
                  }
                />
              </Box>
              <Box
                sx={{
                  textAlign: grid ? 'left' : 'center',
                  position: { xs: "fixed", sm: "unset" },
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  "button": {
                    // width: { xs: "100%", sm: "fit-content" },
                    width: { xs: "100%", sm: "fit-content" },
                    height: { xs: !"60px" },
                    padding: { xs: "12px 40px 30px 40px" }
                  }
                }}
              >
                {
                  sm ?

                  <EusButton
                  // {...props}
                  clr={clr}
                  type='submit'
                  onClick={()=> {}}
                  borderRadius={"6px 6px 0px 0px;"}
                  variant={"primary"}
                  iconName={"next-arrow"}
                  backgroundColor={clr.buttons}
                  disabled={!checked || !isValid }
                  icon={true}
                  size={12}
                  iconAlign={"right"}
                  buttonlabel={true}
                  buttonText={'signUp'}
                  iconSize={"xl"}
                  isFaIcon={false}
                />
                :
                <CustomButton
                type='submit'
                className="eus-button-2"
                clr={clr}
                disabled={ !checked || !isValid}
                variant = "primary"
                padding={"12px 24px"}         
              >
             
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'signUp')[0]?.Label }
               
              </CustomButton>
                }
               
                {/* <Box
                  sx={{
                    my: 3,
                    color: 'success.main',
                  }}
                >
                  <Box sx={{ color: 'success.main' }}>{successMsg}</Box>
                  <Box sx={{ color: 'error.main' }}>{errorMsg}</Box>
                </Box> */}
              </Box>
              {/* <div style={{height:50}}/> */}
            </form>
      </Box>
      </>
    }
      </>
      <PoweredBy padding={ sm ? "0 0 60px 0" : '16px 0 16px 0'} clr="#FFF"/>
    </Drawer>
  );
};

export default LeadDrawer;


{/* <SuccessMessage label={false} msg={language === "da" ? <p>Vi har registreret dit ønske om at bestille en fremvisning.<br/> Du vil blive kontaktet af vores mægler for at aftale nærmere.</p> : <p>We have registered your wish to order a screening. <br />You will be contacted by our broker to make further arrangements</p>} width={422}/> */}