import { FormControlLabel, Radio } from "@mui/material"

export const CustomRadio = ({ register, value, label }) => {
    return <FormControlLabel 
    sx={{ width: "fit-content" }}
    value={value} 
    control={<Radio {...register} icon={<Icon />} checkedIcon={<CheckedIcon />} sx={{ color: "#fff"}} />} 
    label={<span className="paragraph2-light">{label} </span> }
    />
}

const Icon = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="State=False">
            <rect id="Rectangle" x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white" stroke="#D1D1D1"/>
        </g>
    </svg>
    
}

const CheckedIcon = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="State=True">
                    <rect id="Rectangle" x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="white" stroke="#D1D1D1"/>
                    <rect id="Rectangle_2" x="4" y="4" width="8" height="8" rx="4" fill={clr.buttons}/>
                </g>
            </svg>
    
    
}