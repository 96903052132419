import { toggle_drawer } from "state/actions";
import { active_project, filter_rerender, filter_State } from "state/store.global";
import { useSet, useGet, getFocus, setFocus, useAtom } from "state/jotai";
import { _project } from "state/store.projects";
import { Icon } from "assets/Icons_";
import { Controls } from "./Filter.Controls";
import { Button } from "components/Button/Button";
import {
  Head,
  Bottom,
  Scroll,
  Count,
  Split,
  FilterOptions,
  FilterBottom,
} from "./Filter.style";
import { useReset } from "./Filter.Reset";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import { motion } from "framer-motion";
import { Box, useMediaQuery } from "@mui/material";
import { scrolly } from "state/store.global";
import { useBreak } from "hooks/useBreak";
import { useEffect, useRef, useState } from "react";
import { useWindowSize } from "hooks/useWindowResize";
import { Scrollbar } from "react-scrollbars-custom";

export const Filter = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(true);
  const [filterHeight, setFilterHeight] = useState(null);

  const filterRef = useRef();
  const window = useWindowSize();
  const matches = useMediaQuery("(max-width:1600px)");
  const isMobile = useBreak("md_dn");
  const err = { err_message: "look in Filter.jsx" };
  const { pin, pid } = useGet(active_project);
  const rerender = useGet(filter_rerender);
  const active = getFocus(_project, "activefilters")?.[pin]?.[pid] ?? err;
  const options = getFocus(_project, "filteroptions")?.[pin]?.[pid] ?? err;
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? err;
  const count = getFocus(_project, "units")?.[pin]?.[pid]?.Units?.length ?? "";

  const setFilters = setFocus(_project, "activefilters");
  const toggle = useSet(toggle_drawer);
  const reset = useReset({ global: false });
  const setScroll = useSet(scrolly);
  const scroll = useGet(scrolly);
  const [filterState, setFilterState] = useAtom(filter_State);

  const click = () =>{
    setFilterState(false);
     toggle("filter")
     const buttons = document.querySelectorAll('button');
     buttons.forEach(button => button.blur());
  };
  const icon = { icon: "close", color: clr?.icons, size: "lg" };

  const a = {
    animate: { opacity: 1, transition: { duration: 0.5 } },
    initial: { opacity: 0 },
  };
  const handleScroll = (e) => setScroll(e.scrollTop);

  useEffect(() => {
    const fiilterTop = filterRef?.current?.getBoundingClientRect();
    if (filterRef?.current?.getBoundingClientRect().height > filterHeight) {
      !isFilterVisible && setIsFilterVisible(true);
    }

    if (filterRef.current && fiilterTop.top < 88) {
      !filterHeight && setFilterHeight(filterRef.current.clientHeight);
      isFilterVisible && setIsFilterVisible(false);
    }
  }, [window]);

  if (pid && pin >= 0)
    return (
      <Box
        ref={filterRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "space-between",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ height: "100%" }}>
          <Head as={motion.div} {...a} clr={clr} onClick={click}>
            <Box
              sx={{
                display: "flex",
                color: clr.primary_text,
                gap: "10px",
              }}
            >
              <div className="h5">
                {
                  buttonsLabel?.filter(
                    (btn) => btn?.UILabelProperty === "FindaUnitBySetting"
                  )[0]?.Label
                }
              </div>
              <span className="icon-hover-effect ">
                {/* <Icon {...icon} isFaIcon = {true} wrap = {{ position: "absolute", right: "15px" }} /> */}
                <Icon {...icon} isFaIcon={true} />
              </span>
            </Box>
          </Head>
          <Scroll as={motion.div} {...a}>
            <FilterOptions
              matches={matches}
              isMobile={isMobile}
              isFilterVisible={isFilterVisible}
            >
              <Scrollbar
                onScroll={handleScroll}
                trackYProps={{
                  renderer: (props) => {
                    const { elementRef, ...restProps } = props;
                    return (
                      <span
                        {...restProps}
                        ref={elementRef}
                        className="trackY"
                      />
                    );
                  },
                }}
              >
                <Box sx={{ padding: "0 24px 24px 24px" }}>
                  <Controls
                    {...{ active, options, setFilters, key: pid + rerender }}
                  />
                </Box>
              </Scrollbar>
            </FilterOptions>
            <FilterBottom matches={matches}>
              <Bottom clr={clr} scroll={scroll}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    mode={0}
                    click={reset}
                    fontSize="1rem"
                    borderRadius={"6px"}
                  >
                    <span className="eus-button-2">
                      {" "}
                      {
                        buttonsLabel?.filter(
                          (btn) => btn?.UILabelProperty === "Nulstil"
                        )[0]?.Label
                      }{" "}
                    </span>
                  </Button>
                  <Button mode={1} click={click} borderRadius={"6px"}>
                    <span className="eus-button-2 ">
                      {buttonsLabel
                        ?.filter(
                          (btn) => btn?.UILabelProperty === "Vis boliger"
                        )[0]
                        ?.Label?.split(" ")[0] +
                        " " +
                        count +
                        " " +
                        buttonsLabel
                          ?.filter(
                            (btn) => btn?.UILabelProperty === "Vis boliger"
                          )[0]
                          ?.Label?.split(" ")[1]}
                    </span>
                  </Button>
                </Box>
                <PoweredBy padding="16px 0 10px 0" clr={clr.primary_text} />
              </Bottom>
            </FilterBottom>
          </Scroll>
        </Box>
      </Box>
    );
};
