import { Figure, Svg, Group, Line, Text, Circle, Label } from './Compas.style'
import { motion } from 'framer-motion'
import { iso_free, iso_isrotating, northDegree } from 'state/store.global'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { getFocus, useGet } from 'state/jotai'
import { _bootstrap } from 'state/store.bootstrap'

export const Compas = ({ frame, cfg, label, percent, pin }) => {

  const [rmode] = useAtom(iso_free)
  const setNorthDegree = useAtom(northDegree)[1]
  const isrotate = useGet(iso_isrotating);
  const projectType    = getFocus(_bootstrap, 'type')

  const init = cfg?.SLIDER_COMPASS?.[0] ?? 0
  const max = cfg?.LAST_IMAGE
  const min = cfg?.FIRST_IMAGE

  const inc = frame - min

  const degr = init + 360 * (inc / (max - min))

  useEffect(() => {
    setNorthDegree(Math?.round(degr))
  }, [degr])

  const move = (cx = '', cy = '', dir = '-') => ({
    transform: `rotate(${dir}${degr} ${cx} ${cy})`
  })
  
  const animation = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.5, ease: 'linear' },
  }

  const props = { projectType, rmode, isrotate, pin}
  if (!isNaN(degr)) return (
    percent == 100 &&
    <>
      <Figure { ...props } as={motion.div} {...animation}> 
      <Label { ...props }>
          <span className='paragraph1-regular'> {label}</span>
        </Label>
        <svg viewBox='0 0 1000 1000' fill={projectType === "mono" ? "#000" : pin > 0 ? '#000' : '#000'} >
          <g {...move(500, 500, '+')}>
          <path d="M 927.88583,501.07594 C 926.71698,387.24084 880.49642,278.06785 799.38622,197.57428 718.28013,117.08071 608.93221,71.859712 495.39686,71.859712 c -113.53413,0 -221.95413,45.220998 -301.40832,125.714568 -79.45419,80.49357 -123.434551,189.66656 -122.265604,303.50166 1.168972,113.83633 47.391464,223.0085 128.498794,303.50082 81.10736,80.49644 190.45605,125.7154 303.99017,125.7154 113.53534,0 221.95456,-45.21896 301.40749,-125.7154 79.45705,-80.49232 123.43536,-189.66449 122.26644,-303.50082 z m -917.317387,0 C 9.2325,370.97844 59.495545,246.20979 150.30056,154.21691 241.10559,62.224013 365.01368,10.543115 494.76721,10.543115 c 129.75352,0 254.72139,51.680898 347.41814,143.673795 92.69273,91.99288 145.51904,216.76153 146.85495,346.85903 1.33605,130.09746 -48.92789,254.86449 -139.7313,346.85983 -90.80745,91.99128 -214.71392,143.67299 -344.46745,143.67299 -129.75351,0 -254.72301,-51.68171 -347.41733,-143.67299 C 64.729863,755.94043 11.904387,631.1734 10.568443,501.07594 Z M 255.14444,552.8108 c -22.3597,0 -42.01619,-15.90147 -46.63787,-37.93862 -4.8109,-21.84507 6.61657,-44.26243 27.16571,-53.07567 L 655.20604,280.71958 c 18.07797,-7.47326 38.56736,-3.64098 52.2772,10.15527 13.89946,13.60492 17.93012,34.10756 10.47072,52.11912 L 541.69531,765.50583 c -7.27365,17.43439 -24.3583,28.74115 -43.27543,28.74115 -3.05776,0 -6.30902,-0.19175 -9.37461,-0.96078 -22.02417,-4.40658 -38.08426,-23.95026 -38.31642,-46.55972 l -1.36177,-132.59917 -0.62941,-61.3166 h -61.15543 z m 66.8311,-61.31662 h 126.13238 c 33.82662,0 61.43581,27.40035 61.78414,61.31662 l 1.29826,126.46753 137.09111,-328.42809 -326.11509,140.64394 z" />
          </g>
        </svg>
      </Figure>
    </>
  )
  return null

}