import { useState, useEffect } from "react";
import { Backdrop } from "styles/styles.backdrop";
import { useAtom, useGet } from "state/jotai";
import { Box, Stack, CircularProgress } from "@mui/material";
import { MOVEINCALCULATIONS, DEPOSITECALCULATIONS } from "api/api";
import { Icon } from "assets/Icons_";
import styled from "styled-components";
import { active_project, vis_movein_price } from "state/store.global";
import { useActiveUnit } from "components/Unit";
import { Container, Inner, SideText } from "./HousingBenefit.style";
import { _bootstrap } from "state/store.bootstrap";
import Spinner from "react-spinners/BeatLoader";
import { LottiPlay } from "components/LottieAnimation/LottiePlay";

const StyledImage = styled.img`
  width: 637px;
  height: 200px;
  object-fit: cover;
`;

const Row = styled.div`
  padding: 0.4rem 0;
  font-size: 16px;
  font-weight: 400;
  font-family: Arial;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.div`
  min-height: 1px;
  max-height: 1px;
  width: 100%;
  background: lightgray;
`;

export const MoveInPricePortal = ({ MIPC , close , DPP}) => {
  // const [, setmovein] = useAtom(vis_movein_price);

  const unit = useActiveUnit();
  const { pid } = useGet(active_project);
  const type = useGet(_bootstrap).type;
  const mono = type === "mono";

  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  
  const isInvalidValue = (value) =>
    value === null ||
    value === "0" ||
    value === "0,00" ||
    value === "999999" ||
    value === "999.999";

 
  const imageUrl = unit.getImages()[3]?.url;

  const icon = {
    icon: "close6",
    color: "#fff",
    wrap: { position: "absolute", top: 20, right: 20 },
    size: "20",
    click: close,
  };

  return (
    <>
      <Backdrop onClick={close}  />
      <Container>
       
            <StyledImage src={imageUrl} alt="Unit Image" />
            <Icon {...icon} />
            <Inner>
              <h2>Hvad er indflytningspris?</h2>
              <div
                style={{
                  display: "flex",
                  paddingBottom: "24px",
                  fontSize: "17px",
                }}
              >
                <p>
                  Indflytningspris er det beløb, du skal betale for at kunne
                  flytte ind.
                  <p>Det dækker over:</p>
                </p>
              </div>
              <Box>
                <div>
                 
                      <Row>
                        <div>Første måneds husleje*</div>
                        <div>{`${MIPC?.first_months_rent} kr.`}</div>
                      </Row>
                      {!isInvalidValue(MIPC?.prepaid_rent) &&(
                        <>
                      <Box sx={{ height: 5 }} />
                      <Line />
                      <Box sx={{ height: 5 }} />
                  
                  
                  <Row>
                    <div style={{ display: "flex", gap: "4px" }}>
                      Forudbetalt husleje{" "}
                      <SideText>(1 måned x husleje)</SideText>
                    </div>
                    <div>{`${MIPC?.prepaid_rent} kr.`}</div>
                  </Row>
                  </>
                  )}
                  <Box sx={{ height: 5 }} />
                  <Line />
                  <Box sx={{ height: 5 }} />
                  <Row>
                    <div style={{ display: "flex", gap: "4px" }}>
                      Depositum{" "}
                      <SideText>
                        {`(${DPP?.deposit_number_of_month} måneder x husleje)`}
                      </SideText>
                    </div>
                    <div>{`${MIPC?.deposit} kr.`}</div>
                  </Row>
                  <Box sx={{ height: 5 }} />
                  <Line />
                  {!isInvalidValue(MIPC?.account_consumption) && (
                    <Row>
                      <div>Første måneds aconto forbrug</div>
                      <div>{`${MIPC?.account_consumption} kr.`}</div>
                    </Row>
                  )}
                </div>
                {!isInvalidValue(MIPC?.account_consumption) && <Line />}
                <div style={{ height: 10 }} />
                {!isInvalidValue(MIPC?.moveinprice) && (
                  <Row>
                    <div style={{ fontWeight: "600" }}>Indflytningspris</div>
                    <div>
                      <div
                        style={{ fontWeight: "600" }}
                      >{`${MIPC?.moveinprice} kr.`}</div>
                    </div>
                  </Row>
                )}
                <Box sx={{ height: 5 }} />
                <Line />
              </Box>
              <Stack
                direction="row"
                spacing="1px"
                justifyContent="center"
              ></Stack>
            </Inner>
          
      </Container>
    </>
  );
};
