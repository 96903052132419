import styled from 'styled-components/macro'
import { MENU, BREAK_ } from 'constants'

export const FullWidth = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index:50;
  background-color: ${({clr, isBgTrue }) => isBgTrue && `${clr?.footer_bgcolor}` };
  ${BREAK_.md_up} {
    /* margin-left: ${({ nav, left }) =>
      (nav && MENU.DESKTOP_NAV_DRAWER_W) +
      (left && MENU.DESKTOP_FILTER_DRAWER_W)}px; 
    width: ${({ nav, left, right }) =>
      `calc(100% - ${
        300 +
        (nav && MENU.DESKTOP_NAV_DRAWER_W) +
        (left && MENU.DESKTOP_FILTER_DRAWER_W) +
        (right && MENU.DESKTOP_UNIT_DRAWER_W)
      }px)`}; */
  };
  ${BREAK_.md_dn} {
    left: 0;
    width: 100%;
    border-radius: 6px 6px 0px 0px;
  }
  transition: padding-left 0.3s ease, padding-right 0.3s ease,
    margin-top 0.3s ease, margin-left 0.3s ease, height 0.3s ease,
    width 0.3s ease;
`

export const FlexBox = styled.div`
  padding: 16px 48px 8px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ clr }) => clr?.primary_text};
  font-size: 1.125rem;
  transform: translate(0, 0);
  ${BREAK_.md_dn} {
    padding: 8px 16px 8px 16px;
    font-size: 1rem;
    width: 100%;
    border-radius: 0;
  }
`

export const Splitter = styled.div`
  height: calc(100% - 15px);
  width: 2px;
  background-color: lightgray;
  border-radius: 99px;
`

export const Section = styled.div`
  display: flex;
  background-color: ${({ clr , pin, type, isIsometry, isMobile}) => type === "poly" && pin === 0 && isIsometry ? isMobile ? `${clr?.primary}bf` : `#ffffffbf` : `${clr?.primary}bf`};
 
  border-radius: 6px;
  border: 2px solid ${({ clr , pin, type, isIsometry, isMobile }) => type === "poly" && pin === 0 && isIsometry ? isMobile ? `${clr?.primary}` : `#ffffff` : `${clr?.primary}`};
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding ? padding : "12px 0px"};
  gap: 8px;
  height: 44px; 
   box-sizing: border-box;
  line-height: 1;
  cursor: pointer;
  user-select: none;
  ${BREAK_.md_up} {
    height: 44px;
    width: ${({width}) => width ? width : '185px'};
  }
`

export const FooterButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  height: 44px;
  border-radius: 6px;
  
    padding: ${({ padding }) => padding ? padding : "12px 40px"};
  line-height: 1;
  user-select: none;
  white-space: nowrap;
  border: ${({ clr, variant }) =>
    variant === "primary" ? "none" : "1px solid #D1D1D1"};
  
  transition: background-color 0.1s ease-in, border 0.1s ease-in, box-shadow 0.1s ease-in;

  ${BREAK_.md_up} {
    height: 44px;
    width: ${({ width }) => (width ? width : '185px')};
  }

  /* Background color logic */
  background-color: ${({ variant, clr, pin, type, isIsometry, isMobile }) => {
    if (type === "poly" && pin === 0 && isIsometry) {
      return isMobile && variant === "primary"
        ? `${clr?.primary}bf`
        : `${clr?.buttons_text}bf`;
    }
    if (type === "mono" && pin === 0 && isIsometry) {
      return isMobile && variant === "secondary"
        ? `${clr?.buttons_text}bf`
        : `${clr?.primary}bf`
    }
    return variant === "primary" ? `${clr?.primary}bf` :`${clr?.buttons_text}bf`;
  }};

  &:hover {
    background-color: ${({ variant, clr, pin, type, isIsometry, isMobile }) => {
      if (type === "poly" && pin === 0 && isIsometry) {
        return isMobile && variant === "primary"
          ? `${clr?.primary}`
          : clr?.buttons_text;
      }
      if (type === "mono" && pin === 0 && isIsometry) {
        return isMobile && variant === "secondary"
          ? clr?.buttons_text
          : `${clr?.primary}`;
      }
      return variant === "primary" ? `${clr?.primary}` : clr?.buttons_text;
    }};
    
    border: ${({ clr, variant }) =>
      variant === "primary" ? "none" : "1px solid #D1D1D1"};
  }

  &:focus {
    /* Focus styles for both primary and secondary */
   background-color: ${({ variant, clr, pin, type, isIsometry, isMobile , isStatus}) => {
    if(isStatus){
      return 
    }
      if (type === "poly" && pin === 0 && isIsometry ) {
        return isMobile && variant === "primary"
          ? `${clr?.primary}`
          : clr?.buttons_text;
      }
      if (type === "mono" && pin === 0 && isIsometry) {
        return isMobile && variant === "secondary"
          ? clr?.buttons_text
          : `${clr?.primary}`;
      }
      return variant === "primary" ? `${clr?.primary}` : clr?.buttons_text;
    }};
    
   
  }
`;
 /* box-shadow for primary and secondary variants */
//  box-shadow: ${({ variant, clr, isStatus }) => {
//   if (variant === "primary" && !isStatus) {
//     return `0 0 0 2.5px ${clr?.primary}`;  // Primary focus style
//   }
//   if (variant === "secondary" && !isStatus) {
//     return `0 0 0 1.5px #D1D1D1`;  // Secondary focus style (lighter shadow)
//   }
//   return "none";
// }};


